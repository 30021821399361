import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { AuthFunctions } from '../functions';
import { Modal, UserProfile } from '../components';
import '../css/components/header.css';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      project_name: '',
      display_user_modal: false,
      sign_out_flag: false,
    };
  }

  componentDidMount = async () => {
    let name    = getItem('name');
    let email   = getItem('email');
    let project = getItem('project');
    let p_name  = project && project.name ? project.name : 'Project Management';
    if (!email || !name) {
      let user_data = await AuthFunctions.get_logged_in_user();
      let u_name    = getItem('name');
      let u_email   = getItem('email');
      this.setState({ name: u_name, email: u_email, project_name: p_name });
    } else {
      this.setState({ name: name, email: email, project_name: p_name });
    }
  }

  componentDidUpdate = () => {
    let name         = this.state.name  || '';
    let token        = getItem('token');
    let stored_name  = getItem('name');
    let stored_email = getItem('email');
    let project      = getItem('project');
    let p_name       = project && project.name ? project.name : 'Project Management';
    if (name && stored_name && name !== stored_name) {
      this.setState({ name: stored_name, email: stored_email });
    }
    if (!this.state.name && stored_name) {
      this.setState({ name: stored_name });
    }
    if (p_name && this.state.project_name && this.state.project_name !== p_name) {
      this.setState({ project_name: p_name });
    }

    if (!token) {
      this.props.navigate('/');
    }
  }

  render() {
    return (
      <div className="header">
        <Modal display={this.state.display_user_modal}
               title={'User Profile'}
               subtitle={'Edit Your User Profile'}
               onClose={ () => { this.setState({ display_user_modal: false }) }}>
          <UserProfile navigate={this.props.navigate} on_sign_out_completed={this.on_sign_out_completed} />
        </Modal>
        <div className="left-div">
          <span className='drublo-header-title'>drablo</span>
          <span className='header-project-name'>{ this.state.project_name }</span>
        </div>
        <div className="right-div" onClick={ () => { this.setState({ display_user_modal: true }) }}>
          <div className='right-div-name'>{ this.state.name }</div>
          <div>{ this.state.email }</div>
        </div>
      </div>
    );
  }

  on_sign_out_completed = () => {
    this.setState({ sign_out_flag: !this.state.sign_out_flag });
  }
}

export default Header;
