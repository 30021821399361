import React, { Component } from 'react';
import { Line, Input, Button } from '../components';
import { StringUtils } from '../utils';
import Lottie from 'lottie-react';
import '../css/components/internal-use-assignees-pane.css';

class TypesPane extends Component {

  constructor(props) {
    super(props);
    this.state = {
      new_value_input: '',
      display_new_input: false,
      values_modifed: false,
      loading_save: false
    };
  }

  componentDidMount = () => {

  }

  change_value_action = (index, new_value) => {
    let key_converted = StringUtils.displayStringToKey(new_value);
    let new_array     = [...this.props.types];
    new_array[index]  = key_converted;
    this.determine_has_modified(new_array);
    this.props.changeAction(this.props.arr_key, new_array);
  }

  new_value_action = (new_value) => {
    this.setState({ new_value_input: new_value });
  }

  render_add_value_button = () => {
    if (this.state.display_new_input || this.props.locked) {
      return null;
    }

    return <div className="add-type-container">
      <div className="type-curve-container">
        <div className="type-cuver-white-container"></div>
      </div>
      <div className="type-task-button" onClick={ () => { this.setState({ display_new_input: true }) }}>
        <span className='fa-solid fa-circle-plus'></span>
      </div>
    </div>
  }

  render_new_input = () => {
    if (!this.state.display_new_input) {
      return null;
    }

    return <div className='type-pane-row'>
      <Input className='type-input' value={this.state.new_value_input} onChange={ (type_value) => { this.new_value_action(type_value) }} />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: 15 }}>
        <span className='fa-solid fa-trash type-trash-icon' onClick={ () => { this.setState({ display_new_input: false, new_value_input: '' }) }} />
      </div>
    </div>
  }

  render_value_rows = () => {
    let values_array = this.props.types || [];
    let vallue_rows  = values_array.map((type_str, ind) => {
      let display_value = StringUtils.keyToDisplayString(type_str)
      let is_locked     = this.determine_is_locked(type_str);
      return <div className='type-pane-row'>
        <Input className='type-input' value={display_value} locked={is_locked} onChange={ (type_value) => { this.change_value_action(ind, type_value) }} />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: 15 }}>
          { is_locked ? <span className='fa-solid fa-lock type-lock-icon' /> : <span className='fa-solid fa-trash type-trash-icon' onClick={ () => { this.delete_type(type_str) }} /> }
        </div>
      </div>
    })

    let display_loading = vallue_rows.length === 0;

    return <div className='flex-container-column'>
      { display_loading ? this.render_loading() : null }
      { vallue_rows }
      { this.render_new_input() }
    </div>
  }

  render_loading = () => {
    return <div className='types-loading-container'>
      <div className='types-loading-text'>Loading Types</div>
      <Lottie animationData={require('../animations/line-loading.json')} style={{ width: 180, marginTop: -60 }} />
    </div>
  }

  render_buttons_container = () => {
    let is_modified = this.state.values_modifed || this.state.new_value_input !== '';
    if (is_modified) {
      return <div className='flex-container-row'>
        <Button className='type-cancel-button' onClick={ () => { this.cancel_types_action() }} title='Cancel' type='grey' />
        <Button className='type-add-button'    onClick={ () => { this.save_types_action() }}   title='Save'   loading={this.state.loading_save} />
      </div>
    }
  }

  render() {

    return <div className='types-card-container'>
      <div className='types-card' style={{ borderRadius: this.props.locked ? 10 : '' }}>
        <div className='types-card-header'>
          <div className="title">{ this.props.title }</div>
        </div>
        <Line className='types-seperator-line' style={{ marginBottom: 0, marginTop: 0 }} />

        { this.render_value_rows()        }
        { this.render_buttons_container() }

      </div>

      { this.render_add_value_button() }

    </div>
  }

  cancel_types_action = () => {
    this.props.changeAction(this.props.arr_key, this.props.og_types);
    this.setState({ new_value_input: '', display_new_input: false, values_modifed: false });
  }

  save_types_action = () => {
    let values_array = this.props.types || [];
    let final_array  = [ ...values_array ];
    if (this.state.new_value_input) {
      let value_key = StringUtils.displayStringToKey(this.state.new_value_input);
      final_array.push(value_key);
    }
    this.props.saveAction(this.props.arr_key, final_array, this.save_success, this.save_failure);
    this.setState({ loading_save: true });
  }

  save_success = () => {
    this.setState({ new_value_input: '', display_new_input: false, values_modifed: false, loading_save: false });
  }

  save_failure = () => {
    this.setState({ loading_save: false });
  }

  determine_has_modified = (new_array) => {
    let og_values = this.props.og_types || [];
    let values    = new_array;
    let modified  = false;

    if (og_values.length !== values.length) {
      modified = true;
    }

    og_values.forEach((val, idx) => {
      let og_val = val;
      let rg_val = values && values[idx] ? values[idx] : '';
      if (rg_val !== og_val) {
        modified = true;
      }
    });

    this.setState({ values_modifed: modified });
  }

  determine_is_locked = (type_str) => {
    let is_locked = false;

    if (type_str === 'requirements' || type_str === 'completed' || type_str === 'in_progress' || type_str === 'member') {
      is_locked = true;
    }

    if (this.props.locked) {
      is_locked = true;
    }

    return is_locked;
  }

  delete_type = (type_value) => {
    let current_values = this.props.types;
    let new_values     = [];
    current_values.forEach((val, i) => {
      if (val !== type_value) {
        new_values.push(val);
      }
    });
    this.determine_has_modified(new_values);
    this.props.changeAction(this.props.arr_key, new_values);
  }
}

export default TypesPane;
