import React, { Component } from 'react';
import '../css/components/line.css';

class Line extends Component {

  render() {
    let class_name = this.props.className || '';
        class_name = 'default-line' + ' ' + class_name;

    if (this.props.hide) {
      return null
    }

    return (
      <div className={class_name} style={{...this.props.style}}></div>
    );
  }
}

export default Line;
