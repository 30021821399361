import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { ProjectFunctions, TeamFunctions } from '../functions';
import { Header, NavigationPane, NewPhase, NewTask, TaskDetails, PhaseDetails, PhasePane, CompletedPhases } from '../components';
import '../css/screens/phases-screen.css';
import Lottie from 'lottie-react';

class PhasesScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      right_pane_data: {},
      phases: [],
      completed_phases: [],
      members: [],
      internal_users: [],
      status_types: [],
      task_types: [],
      tasks_dict: {},
      selected_completed_phase: {},
      display_phases_type: 'active',
      loading_new_task: false,
      loading_save_task: false,
      loading_save_phase: false,
      loading_new_phase: false,
      initial_loading: false
    };
  }

  componentDidMount = async () => {
    let project    = getItem('project')
    let project_id = project && project.id ? project.id : '';

    let phases         = [];
    let members        = [];
    let internal_users = [];
    let status_types   = [];
    let task_types     = [];
    let tasks_dict     = {};

    if (project_id) {
      this.setState({ initial_loading: true });

      let project_types_res = await ProjectFunctions.get_project_types(project_id);
      let project_types     = project_types_res && project_types_res.success && project_types_res.project_types ? project_types_res.project_types : {};

      setItem('project_types', project_types);

      status_types = project_types.task_status_types || [];
      task_types   = project_types.task_types        || [];

      let members_res        = await TeamFunctions.get_team_members(project_id);
      let phases_res         = await ProjectFunctions.get_phases(project_id, 'active');
      let internal_users_res = await TeamFunctions.get_internal_users(project_id);

      members        = members_res && members_res.success && members_res.members ? members_res.members : {};
      phases         = phases_res  && phases_res.success  && phases_res.phases   ? phases_res.phases   : [];
      internal_users = internal_users_res && internal_users_res.success && internal_users_res.project_internal_users && internal_users_res.project_internal_users.internal_users ? internal_users_res.project_internal_users.internal_users : [];

      this.populate_tasks_dict(phases);
      this.pull_completed_phases(project_id);
    }

    this.setState({ phases: phases, members: members, status_types: status_types, task_types: task_types, internal_users: internal_users, initial_loading: false });
  }

  render_right_pane_content = (section_name) => {
    let right_pane_data      = this.state.right_pane_data;
    let right_pane_component = right_pane_data && right_pane_data.section_name ? right_pane_data.section_name : '';

    switch (right_pane_component) {
      case 'new_phase':
        return <NewPhase data={right_pane_data} loading={this.state.loading_new_phase} new_phase_action={ (phase, close_edit_action) => { this.new_phase_action(phase, close_edit_action) }} />
      case 'phase_details':
        return <PhaseDetails data={right_pane_data} loading={this.state.loading_save_phase} save_phase_action={ (phase, close_edit_action) => { this.save_phase_action(phase, close_edit_action) }} close_action={ () => { this.change_right_pane_to_completed() }} display_close={this.state.display_phases_type === 'completed'} />
      case 'new_task':
        return <NewTask      tasks_dict={this.state.tasks_dict} data={right_pane_data} members={this.state.members} internal_users={this.state.internal_users} task_types={this.state.task_types} status_types={this.state.status_types} loading={this.state.loading_new_task} new_task_action={ (new_task) => { this.new_task_action(new_task) }} />
      case 'task_details':
        return <TaskDetails  tasks_dict={this.state.tasks_dict} data={right_pane_data} members={this.state.members} internal_users={this.state.internal_users} task_types={this.state.task_types} status_types={this.state.status_types} loading={this.state.loading_save_task} save_task_action={ (task, close_edit_action) => { this.save_task_action(task, close_edit_action) }} close_action={ () => { this.change_right_pane_to_completed() }} display_close={this.state.display_phases_type === 'completed'} />
      case 'completed_phases':
        return <CompletedPhases phases={this.state.completed_phases} tasks_dict={this.state.tasks_dict} completed_phase_action={ (phase) => { this.selected_completed_phase(phase) } }/>
      default:
        return null;
    }
  }

  render_phase_panes = () => {
    if (this.state.initial_loading) {
      return <div className='phases-screen-loading-container'>
        <Lottie animationData={require('../animations/phases-loading.json')} style={{ height: 70, width: 70 }} />
        <div className='phases-screen-loading-text'>Loading Phases</div>
        <Lottie animationData={require('../animations/line-loading.json')} style={{ width: 200, marginTop: -65 }} />
      </div>
    }
    return <div>
      <PhasePane phases={this.state.phases}
                 members={this.state.members}
                 internal_users={this.state.internal_users}
                 initial_loading={this.state.initial_loading}
                 onTaskDragged={ (dragged_data) => { this.task_drag_action(dragged_data) }}
                 onRightPaneChange={ (right_pane_data) => { this.right_pane_change(right_pane_data) }}
                 onChangePhasesType={ (phases_type) => { this.display_phases_type_action(phases_type) } }
                 completed_phases_open={ this.state.display_phases_type === 'completed' }
                 completed_phase_selected={ this.state.selected_completed_phase }
                 complete_phase_action={ (phase) => { this.complete_phase_action(phase) }}
                 loading_complete_phase={ this.state.loading_complete_phase }
                />
    </div>
  }

  render() {
    return (
      <div>
        <Header navigate={this.props.navigate} />
        <div className='phases-screen-container'>
          <div className='main-pane main-container-sections'>
            <NavigationPane tab='phases' navigate={this.props.navigate} />
            <div style={{ flex: 1, marginLeft: 10 }}>
              { this.render_phase_panes() }
            </div>
          </div>
          <div className='right-pane'>
            { this.render_right_pane_content() }
          </div>
        </div>
      </div>
    );
  }

  task_drag_action = async (dragged_data) => {
    let phase_id     = dragged_data.phase_id;
    let task_id      = dragged_data.task_id;
    let new_index    = dragged_data.new_index;
    let phases       = this.state.phases           || [];
    let c_phases     = this.state.completed_phases || [];
    let new_tasks    = [];
    let new_phases   = [];
    let new_c_phases = [];
    let updated_c_ph = {};

    phases.forEach((phase, i) => {
      let p_id      = phase.id;
      let new_phase = Object.assign({}, phase);
      if (phase_id === p_id) {
        let tasks       = phase.tasks || [];
        let moved_task  = {};
        let subtracted  = tasks.map((task) => { if (task.id === task_id) { moved_task = task; return null } else { return task } }).filter((tsk) => { return tsk });
            subtracted.splice(new_index, 0, moved_task);
        new_tasks       = subtracted;
        new_phase.tasks = subtracted;
        new_phases.push(new_phase);
      } else {
        new_phases.push(new_phase);
      }
    });

    c_phases.forEach((phase, i) => {
      let p_id      = phase.id;
      let new_phase = Object.assign({}, phase);
      if (phase_id === p_id) {
        let tasks       = phase.tasks || [];
        let moved_task  = {};
        let subtracted  = tasks.map((task) => { if (task.id === task_id) { moved_task = task; return null } else { return task } }).filter((tsk) => { return tsk });
            subtracted.splice(new_index, 0, moved_task);
        new_tasks       = subtracted;
        new_phase.tasks = subtracted;
        updated_c_ph    = new_phase;
        new_c_phases.push(new_phase);
      } else {
        new_c_phases.push(new_phase);
      }
    });

    let task_positions    = new_tasks.map((task) => { return task.id });
    let updated_positions = ProjectFunctions.update_task_positions({ phase_id: phase_id, task_positions: task_positions });

    this.setState({ phases: new_phases, completed_phases: new_c_phases, selected_completed_phase: updated_c_ph })
  }

  selected_completed_phase = (phase) => {
    this.setState({ selected_completed_phase: phase })
  }

  complete_phase_action = async (phase) => {
    this.setState({ loading_complete_phase: true });

    let project_id         = phase.project_id;
    let complete_phase_res = await ProjectFunctions.complete_phase(phase.id);
    let is_success         = complete_phase_res && complete_phase_res.success ? true : false;

    this.pull_phases(project_id);
    this.pull_completed_phases(project_id);
    this.setState({ loading_complete_phase: false });
  }

  save_phase_action = async (phase, close_edit_action) => {
    this.setState({ loading_save_phase: true });

    let update_phase_res = await ProjectFunctions.update_phase(phase);
    let is_success       = update_phase_res && update_phase_res.success ? true : false;

    if (is_success && close_edit_action) {
      close_edit_action();
    }

    this.pull_phases(phase.project_id);
    this.setState({ loading_save_phase: false });
  }

  save_task_action = async (task, close_edit_action) => {
    this.setState({ loading_save_task: true });

    let save_task_res = await ProjectFunctions.save_task(task);
    let is_success    = save_task_res && save_task_res.success ? true : false;

    if (is_success && close_edit_action) {
      close_edit_action();
    }

    this.pull_phases(task.project_id);
    this.pull_completed_phases(task.project_id);
    this.setState({ loading_save_task: false });
  }

  new_task_action = async (new_task) => {
    this.setState({ loading_new_task: true });

    let new_task_res = await ProjectFunctions.create_new_task(new_task);
    let is_success   = new_task_res && new_task_res.success ? true : false;

    this.pull_phases(new_task.project_id);
    this.setState({ loading_new_task: false, right_pane_data: is_success ? {} : this.state.right_pane_data });
  }

  new_phase_action = async (new_phase) => {
    this.setState({ loading_new_phase: true });

    let phase_res  = await ProjectFunctions.create_new_phase(new_phase);
    let is_success = phase_res && phase_res.success ? true : false;

    this.pull_phases(new_phase.project_id);
    this.setState({ loading_new_phase: false, right_pane_data: is_success ? {} : this.state.right_pane_data });
  }

  pull_phases = async (project_id) => {
    let phases_res = await ProjectFunctions.get_phases(project_id, 'active');
    let phases     = phases_res && phases_res.success && phases_res.phases ? phases_res.phases : [];
    this.populate_tasks_dict(phases);
    this.setState({ phases: phases });
  }

  pull_completed_phases = async (project_id) => {
    let phases_res = await ProjectFunctions.get_phases(project_id, 'completed');
    let phases     = phases_res && phases_res.success && phases_res.phases ? phases_res.phases : [];
    this.populate_tasks_dict(phases);
    this.setState({ completed_phases: phases });
  }

  populate_tasks_dict = (phases) => {
    let tasks_dict = Object.assign({}, this.state.tasks_dict);
    let selected   = this.state.selected_completed_phase;

    phases.forEach((phase) => {
      let tasks = phase.tasks || [];
      tasks.forEach((task) => { tasks_dict[task.id] = task });
      if (selected && selected.id && phase.id === selected.id) {
        selected = phase;
      }
    });
    this.setState({ tasks_dict: tasks_dict, selected_completed_phase: selected });
  }

  display_phases_type_action = (phases_type) => {
    let project    = getItem('project')
    let project_id = project && project.id ? project.id : '';
    this.pull_phases(project_id);
    this.pull_completed_phases(project_id);
    this.setState({ display_phases_type: phases_type })
  }

  change_right_pane_to_completed = () => {
    this.setState({ right_pane_data: { section_name: 'completed_phases' } });
  }

  right_pane_change = (right_pane_data) => {
    this.setState({ right_pane_data: right_pane_data });
  }

}

export default PhasesScreen;
