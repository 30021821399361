import { setItem, getItem } from '../storage';
import { UtilityFunctions } from '../functions';
import axios from 'axios';

const create_new_project = async (project_data) => {
  let created_project_data = await UtilityFunctions.post('/createNewProject', project_data);
  return created_project_data;
}

const update_project = async (project_data) => {
  let updated_project_data = await UtilityFunctions.post('/updateProject', project_data);
  return updated_project_data;
}

const get_user_projects = async () => {
  let user_projects = await UtilityFunctions.get('/getProjects');
  return user_projects;
}

const get_project_types = async (project_id) => {
  let project_types_data = await UtilityFunctions.post('/getProjectTypes', { project_id: project_id });
  return project_types_data;
}

const update_project_types = async (project_types) => {
  let project_types_data = await UtilityFunctions.post('/updateProjectTypes', project_types);
  return project_types_data;
}

const create_new_phase = async (phase_data) => {
  let created_phase_data = await UtilityFunctions.post('/createNewPhase', phase_data);
  return created_phase_data;
}

const get_phases = async (project_id, status) => {
  let request_obj = { project_id: project_id, status: status || '' }
  let created_phase_data = await UtilityFunctions.post('/getProjectPhases', request_obj);
  return created_phase_data;
}

const update_phase = async (phase_data) => {
  let update_phase_data = await UtilityFunctions.post('/updatePhase', phase_data);
  return update_phase_data;
}

const update_task_positions = async (position_data) => {
  let update_task_positions = await UtilityFunctions.post('/updateTaskPositions', position_data);
  return update_task_positions;
}

const complete_phase = async (phase_id) => {
  let complete_phase_data = await UtilityFunctions.post('/completePhase', { id: phase_id });
  return complete_phase_data;
}

const create_new_task = async (task_data) => {
  let created_task_data = await UtilityFunctions.post('/createNewTask', task_data);
  return created_task_data;
}

const save_task = async (task_data) => {
  let save_task_data = await UtilityFunctions.post('/saveTask', task_data);
  return save_task_data;
}

const create_comment = async (comment_data) => {
  let created_comment_data = await UtilityFunctions.post('/createComment', comment_data);
  return created_comment_data;
}

const delete_comment = async (comment_data) => {
  let deleted_comment_data = await UtilityFunctions.post('/deleteComment', comment_data);
  return deleted_comment_data;
}

const get_comments = async (comment_data) => {
  let comments_data = await UtilityFunctions.post('/getComments', comment_data);
  return comments_data;
}

export default {
  complete_phase,
  create_comment,
  create_new_phase,
  create_new_project,
  create_new_task,
  delete_comment,
  get_comments,
  get_phases,
  get_project_types,
  get_user_projects,
  save_task,
  update_phase,
  update_project,
  update_project_types,
  update_task_positions
}
