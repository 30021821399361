import React, { Component } from 'react';
import '../css/components/modal.css';

class Modal extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render_modal_content = () => {
    let title    = this.props.title    || '';
    let subtitle = this.props.subtitle || '';
    let locked   = this.props.locked === true ? true : false;
    return <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">
          <div className='modal-header'>
            <div>
              <div className='modal-header-title'>{ title }</div>
              { subtitle ? <div className='modal-header-subtitle'>{ subtitle }</div> : null }
            </div>
            { locked ? null : <span className='fa-solid fa-circle-xmark modal-close-button' onClick={ () => { this.onClose() }}/> }
          </div>
          { this.props.children }
        </div>
      </div>
    </div>
  }

  render() {
    let display = this.props.display === true ? true : false;
    return display ? this.render_modal_content() : null;
  }
}

export default Modal;
