import React, { Component } from 'react';
import '../css/components/received-invitations.css';
import { Button, Line } from '../components';

class ReceivedInvitations extends Component {

  get_invitation_rows = (invitations) => {
    let invites = invitations || [];

    let rows = invites.map((invitation, index) => {
      let project_id    = invitation.project_id;
      let project_name  = invitation.project_name;
      let inviter_email = invitation.invited_by;
      let is_last       = index === invites.length - 1;
      let is_a_loading  = this.props.loadingData && this.props.loadingData.project_id === project_id && this.props.loadingData.button === 'accept'  ? true : false;
      let is_d_loading  = this.props.loadingData && this.props.loadingData.project_id === project_id && this.props.loadingData.button === 'decline' ? true : false;
      return (
        <div className='invite-row-container' key={invitation.id}>
          <div className='invite-row' key={invitation.id}>
            <div className='invite-project-title'>{ invitation.project_name }</div>
            <div className='invite-details-container'>
              <div className='invite-details-text'>Invited By</div>
              <div className='invite-details-text'>{ inviter_email }</div>
            </div>
            <div className='invite-buttons-container'>
              <Button title='Decline'
                      loading={is_d_loading}
                      className='invite-decline-button'
                      type='grey'
                      onClick={ () => {
                        let invite_data = { email: invitation.email, project_id: invitation.project_id, accepted: false, declined: true }
                        this.props.respondAction(invite_data);
                      }} />
              <Button title='Accept'
                      loading={is_a_loading}
                      className='invite-accept-button'
                      onClick={ () => {
                        let invite_data = { email: invitation.email, project_id: invitation.project_id, accepted: true,  declined: false }
                        this.props.respondAction(invite_data);
                      }} />
            </div>
          </div>
          <Line className='invite-separator-lines' hide={is_last} />
        </div>
      );
    }).filter((row) => { return row });

    return rows;
  };

  render() {
    let invitations = this.props.invitations || [];
    let invite_rows = this.get_invitation_rows(invitations);
    return (
      <div className='received-invitations-container'>
        { invite_rows }
      </div>
    );
  }
}

export default ReceivedInvitations;
