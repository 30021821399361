import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { StringUtils } from '../utils';
import { Button, Line, Input, Dropdown, Switch } from '../components';
import '../css/components/new-task.css';

class NewTask extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      assignee_id: '',
      type: '',
      status: '',
      estimated_hours: 0,
      completed_hours: 0,
      progress: 0
    };
  }

  componentDidMount = async () => {
    let data       = this.props.data;
    let phase_id   = data.phase_id;
    let phase_name = data.phase_name;
    this.setState({ phase_id: phase_id, phase_name: phase_name });
  }

  componentDidUpdate = async () => {
    let data       = this.props.data;
    let phase_id   = data.phase_id;
    let phase_name = data.phase_name;
    this.setState({ phase_id: phase_id, phase_name: phase_name });
  }

  get_assignee_options = () => {
    let members   = this.props.members        || [];
    let internal  = this.props.internal_users || [];

    let internal_converted = internal.map((internal_user) => { return 'internal_' + internal_user });

    let names_arr = members.map((member) => { return member.name });
        names_arr = [ ...names_arr, ...internal_converted ];

    return names_arr;
  }

  get_type_options = () => {
    let task_types = this.props.task_types || [];
    let types_arr  = task_types.map((task) => { return StringUtils.keyToDisplayString(task) });
    return types_arr;
  }

  get_status_options = () => {
    let status_types = this.props.status_types || [];
    let status_arr   = status_types.map((status) => { return StringUtils.keyToDisplayString(status) });
    return status_arr;
  }

  assignee_selected = (selected) => {
    let assignee_id = '';
    let members     = this.props.members        || [];
    let internal_us = this.props.internal_users || [];

    members.forEach((member, i) => {
      if (member.name === selected) {
        assignee_id = member.user_id;
      }
    });

    internal_us.forEach((internal_user, i) => {
      let with_prefix = 'internal_' + internal_user;
      if (with_prefix === selected) {
        assignee_id = with_prefix;
      }
    });

    this.setState({ assignee_id: assignee_id })
  }

  type_selected = (selected) => {
    this.setState({ type: selected });
  }

  status_selected = (selected) => {
    this.setState({ status: selected });
  }

  get_assignee_label = () => {
    let assignee_label = '';
    let assignee_id    = this.state.assignee_id;
    let members        = this.props.members || [];
    let internal_users = this.props.internal_users || [];

    members.forEach((member, i) => {
      if (member.user_id === assignee_id) {
        assignee_label = member.name
      }
    });

    internal_users.forEach((internal_user, i) => {
      let with_prefix = 'internal_' + internal_user;
      if (with_prefix === assignee_id) {
        assignee_label = StringUtils.keyToDisplayString(with_prefix);
      }
    });

    return assignee_label;
  }

  get_type_label = () => {
    let type       = this.state.type;
    let type_label = StringUtils.keyToDisplayString(type);
    return type_label;
  }

  get_status_label = () => {
    let status       = this.state.status;
    let status_label = StringUtils.keyToDisplayString(status);
    return status_label;
  }

  render_percent_field = () => {
    if (this.state.track_hours === true) { return null }

    return <div className='task-details-input-row'>
      <div className='task-details-input-wide-key'>Progress</div>
      <div className='task-details-hours-container'>
        <span className='fa-solid fa-circle-minus task-details-hours-button' onClick={ () => { this.decrement_progress_percent() }}></span>
        <span className='task-details-progress-number'>{ this.state.progress + ' %' }</span>
        <span className='fa-solid fa-circle-plus  task-details-hours-button' onClick={ () => { this.increment_progress_percent() }}></span>
      </div>
    </div>
  }

  render_hours_fields = () => {
    if (!this.state.track_hours) { return null }

    return <div>
      <div className='task-details-input-row'>
        <div className='task-details-input-wide-key'>Estimated Hours</div>
        <div className='task-details-hours-container'>
          <span className='fa-solid fa-circle-minus task-details-hours-button' onClick={ () => { this.decrement_estimated_hours() }}></span>
          <span className='task-details-hours-number'>{ this.state.estimated_hours }</span>
          <span className='fa-solid fa-circle-plus  task-details-hours-button' onClick={ () => { this.increment_estimated_hours() }}></span>
        </div>
      </div>
      <div className='task-details-input-row'>
        <div className='task-details-input-wide-key'>Completed Hours</div>
        <div className='task-details-hours-container'>
          <span className='fa-solid fa-circle-minus task-details-hours-button' onClick={ () => { this.decrement_completed_hours() }}></span>
          <span className='task-details-hours-number'>{ this.state.completed_hours }</span>
          <span className='fa-solid fa-circle-plus  task-details-hours-button' onClick={ () => { this.increment_completed_hours() }}></span>
        </div>
      </div>
    </div>
  }

  render() {

    let assignee_label   = this.get_assignee_label();
    let type_label       = this.get_type_label();
    let status_label     = this.get_status_label();

    let assignee_options = this.get_assignee_options();
    let type_options     = this.get_type_options();
    let status_options   = this.get_status_options();

    return (
      <div className='right-pane-container'>
        <div className='title task-details-title'>Add Task</div>
        <div className='task-details-subtitle'>{ this.state.phase_name }</div>
        <div className='task-edit-section-buffer' />
        <div className='new-task-input-row'>
          <div className='new-task-input-key'>Name</div>
          <Input className='new-task-input' value={this.state.name} placeholder='Enter Name' onChange={ (name) => { this.setState({ name: name }) }} />
        </div>
        <div className='new-task-input-row'>
          <div className='new-task-input-key'>Assignee</div>
          <Dropdown label={assignee_label} placeholder='Select Assignee' height={23} width={190} options={ assignee_options } onSelect={ (selected) => { this.assignee_selected(selected) }} />
        </div>
        <div className='new-task-input-row'>
          <div className='new-task-input-key'>Type</div>
          <Dropdown label={type_label} placeholder='Select Type' height={23} width={190} options={ type_options } onSelect={ (selected) => { this.type_selected(selected) }} />
        </div>
        <div className='new-task-input-row'>
          <div className='new-task-input-key'>Status</div>
          <Dropdown label={status_label} placeholder='Select Status' height={23} width={190} options={ status_options } onSelect={ (selected) => { this.status_selected(selected) }} />
        </div>
        <div className='task-details-input-row'>
          <div className='task-details-input-key'>Track Hours</div>
          <div className='task-details-track-hours-switch-container'>
            <Switch on={this.state.track_hours} onClick={ () => { this.setState({ track_hours: !this.state.track_hours }) }} />
          </div>
        </div>

        { this.render_percent_field() }
        { this.render_hours_fields()  }

        <Button loading={this.props.loading} className='new-task-button' title='Add Task' onClick={ () => { this.add_task() }} />
      </div>
    );
  }

  add_task = () => {
    let project    = getItem('project')
    let project_id = project && project.id ? project.id : '';

    let new_task = {
      name: this.state.name,
      assignee_id: this.state.assignee_id,
      type: StringUtils.displayStringToKey(this.state.type),
      status: StringUtils.displayStringToKey(this.state.status),
      estimated_hours: this.state.estimated_hours,
      completed_hours: this.state.completed_hours,
      phase_id: this.state.phase_id,
      progress: this.state.progress,
      project_id: project_id
    }

    if (this.props.new_task_action) {
      this.props.new_task_action(new_task);
    }
  }

  increment_estimated_hours = () => {
    let current_hours = this.state.estimated_hours;
    let new_hours     = current_hours + 1;
    this.setState({ estimated_hours: new_hours });
  }

  decrement_estimated_hours = () => {
    let current_hours = this.state.estimated_hours;
    let new_hours     = current_hours > 0 ? current_hours -1 : 0;
    this.setState({ estimated_hours: new_hours });
  }

  increment_completed_hours = () => {
    let current_hours = this.state.completed_hours;
    let new_hours     = current_hours + 1;
    this.setState({ completed_hours: new_hours });
  }

  decrement_completed_hours = () => {
    let current_hours = this.state.completed_hours;
    let new_hours     = current_hours > 0 ? current_hours -1 : 0;
    this.setState({ completed_hours: new_hours });
  }

  increment_progress_percent = () => {
    let current_progress = this.state.progress;
    let new_progress     = current_progress < 100 ? current_progress + 10 : 100;
    this.setState({ progress: new_progress });
  }

  decrement_progress_percent = () => {
    let current_progress = this.state.progress;
    let new_progress     = current_progress > 0 ? current_progress - 10 : 0;
    this.setState({ progress: new_progress });
  }
}

export default NewTask;
