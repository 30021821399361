import '../css/screens/landing-screen.css';
import React, { Component } from 'react';
import { auth, provider, signInWithPopup } from '../firebase';
import { getItem, setItem } from '../storage';
import { AuthFunctions }    from '../functions';
import imageSrc from '../assets/google.png';
import Lottie from 'lottie-react';

class LandingScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = async () => {
    let validity_data = await AuthFunctions.verify_access_token();
    let is_valid      = validity_data && validity_data.is_valid === true ? true : false;

    if (is_valid) {
      this.navigate_to_projects();
    }
  }

  navigate_to_projects = () => {
    this.props.navigate('/projects');
  }

  google_sign_in = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      let token = result && result.user && result.user.accessToken ? result.user.accessToken : '';
      let uid   = result && result.user && result.user.uid         ? result.user.uid         : '';
      setItem('token', token);
      setItem('user_id', uid);
      let user  = await AuthFunctions.get_logged_in_user();
      if (token && uid) {
        this.navigate_to_projects();
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div className="fullscreen">
        <div className='center-container'>
          <Lottie animationData={require('../animations/landing-animation.json')} style={{ height: 70, width: 70 }} />
          <div className='main-title'>drablo</div>
          <div className='sign-in-google-button' onClick={ () => { this.google_sign_in() }}>
            <img src={ imageSrc } style={{ height: 15, width: 15, marginRight: 5 }} />
            <span className='sign-in-google-button-title'>Sign In With Google</span>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingScreen;
