import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { TeamFunctions    } from '../functions';
import { Line, ProgressBar, Input } from '../components';
import Lottie from 'lottie-react';
import '../css/components/team-invite-pane.css';

class TeamInvitePane extends Component {

  constructor(props) {
    super(props);
    this.state = {
      project_id: '',
      project_invites: [],
      invitee_email: '',
      loading: false
    };
  }

  componentDidMount = async () => {
    this.pull_project_invites();
  }

  pull_project_invites = async () => {
    let project        = getItem('project')
    let project_id     = project && project.id ? project.id : '';
    let project_invite = [];
    let invites_res    = await TeamFunctions.get_sent_invites(project_id);
    let invites        = invites_res && invites_res.success && invites_res.project_invites ? invites_res.project_invites : [];
    this.setState({ project_invites: invites });
  }

  send_invite = async () => {
    if (!this.state.invitee_email) { return null }
    this.setState({ loading: true });

    let invited_by  = getItem('email')
    let project     = getItem('project')
    let project_id  = project && project.id ? project.id : '';
    let invite_data = { email: this.state.invitee_email, project_id: project_id, invited_by: invited_by }
    let invite_res  = await TeamFunctions.create_project_invite(invite_data);
    this.pull_project_invites();

    this.setState({ loading: false, invitee_email: '' });
  }

  cancel_invite = async (email, project_id) => {
    if (email && project_id) {
      let invite_data = { email: email, project_id: project_id }
      let invite_res  = await TeamFunctions.cancel_project_invite(invite_data);
      this.pull_project_invites();
    }
  }

  render_invites_list = () => {
    let invites     = this.state.project_invites || [];
    let invite_rows = invites.map((invite) => {
      let invite_email = invite.email;
      let project_id   = invite.project_id;
      return <div className='team-invite-pending-row'>
        <div className='team-invite-pending-emails'>{ invite_email }</div>
        <div className='team-invite-pending-cancel' onClick={ () => { this.cancel_invite(invite_email, project_id) }}>Cancel</div>
      </div>
    })

    if (invite_rows.length === 0) { return null }

    return <div className='flex-container-column'>
      <Line />
      <div className='title team-invite-pending-title'>Pending Invites</div>
      { invite_rows }
    </div>
  }

  render() {
    if (this.determine_hide()) { return null }
    return (
      <div className='right-pane-container'>
        <div className='title team-invite-details-title'>Invite Team Member</div>
        <div className='team-invite-input-row'>
          <div className='team-invite-input-key'>Email</div>
          <Input className='team-invite-input' placeholder='Email Address' value={this.state.invitee_email} onChange={ (email_address) => { this.setState({ invitee_email: email_address }) }} />
          <div className='invite-send-button' onClick={ () => { this.send_invite() }}>
            { this.state.loading ? <Lottie animationData={require('../animations/white-spinner.json')} style={{ height: 15, width: 15 }} />
                                 : <span className='fa-solid fa-circle-arrow-right'/> }
          </div>
        </div>

        { this.render_invites_list() }
      </div>
    );
  }

  determine_hide = () => {
    let project           = getItem('project')
    let project_id        = project && project.id ? project.id : '';
    let hide              = false;
    let all_member_access = getItem('member_access') || {};
    let project_access    = all_member_access[project_id];

    if (project_access === 'tasks' || project_access === 'phases') {
      hide = true;
    }

    return hide;
  }

}

export default TeamInvitePane;
