
const sentenceCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const displayStringToKey = (display_string) => {
  let key_string  = '';
  let display_str = display_string || '';
      display_str = display_str.toLowerCase();

  let words       = display_str.split(' ');

  words.forEach((word, i) => {
    let is_last = i === words.length - 1;
    if (is_last) {
      key_string = key_string + word;
    } else {
      key_string = key_string + word + '_';
    }
  });

  return key_string;
}

const keyToDisplayString = (key_string) => {
  let display_string = '';
  let key_str        = key_string || '';
  let next_is_upper  = false;

  if (key_str.includes('internal_')) {
    key_str = key_str.replace('internal_', '');
  }

  for (var i = 0; i < key_str.length; i++) {
    let char = key_str.charAt(i);
    if (next_is_upper) {
      char = char.toUpperCase();
      next_is_upper = false;
    }
    if (i === 0) {
      char = char.toUpperCase();
    }
    if (char === '_') {
      char = ' ';
      next_is_upper = true;
    }

    display_string  = display_string + char;
  }

  return display_string;
}

export default {
  keyToDisplayString,
  displayStringToKey,
  sentenceCase
}
