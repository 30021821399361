import React, { Component } from 'react';
import '../css/components/button.css';
import Lottie from 'lottie-react';

class Button extends Component {

  getClassNames = () => {
    let type         = this.props.type      || 'default';
    let passed_class = this.props.className || '';

    switch (type) {
      case 'default':
        return 'button-default' + ' ' + passed_class;
      case 'grey':
        return 'button-grey' + ' ' + passed_class;
      default:

    }
  };

  handle_click = () => {
    const { onClick } = this.props;

    if (onClick) {
      onClick();
    }
  };

  render() {
    let is_disabled  = this.props.disabled || false;
    let class_name   = this.getClassNames();
    let title        = this.props.title || '';
    let style        = this.props.style || {};
    let is_loading   = this.props.loading === true ? true : false;

    return (
      <span className={class_name} onClick={this.handle_click} style={{ ...style }}>
        { is_loading ? <Lottie animationData={require('../animations/white-spinner.json')} style={{ height: 15, width: 15 }} />
                     : <span>{ title }</span> }
      </span>
    );
  }
}

export default Button;
