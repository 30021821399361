import React, { Component } from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import { LandingScreen, PhasesScreen, ProjectsScreen, SettingsScreen, TeamScreen } from './screens';
import { useNavigate } from 'react-router-dom';
import './css/universal.css';

function App() {
  const navigate = useNavigate();

  return (
      <Routes>
        <Route path="/" element={<LandingScreen navigate={navigate} />} />
        <Route path="/projects" element={<ProjectsScreen navigate={navigate} />} />
        <Route path="/phases" element={<PhasesScreen navigate={navigate} />} />
        <Route path="/team" element={<TeamScreen navigate={navigate} />} />
        <Route path="/settings" element={<SettingsScreen navigate={navigate} />} />
      </Routes>
  );
}

export default App;
