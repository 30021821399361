import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { ProjectFunctions } from '../functions';
import { Header, NavigationPane, TypesPane, SettingsProjectName } from '../components';
import '../css/screens/settings-screen.css';

class SettingsScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      og_task_types: [],
      og_task_status_types: [],
      og_team_member_role_types: [],
      task_types: [],
      task_status_types: [],
      team_member_role_types: [],
      project_types: {},
      project: {},
      loading_save_project: false
    };
  }

  componentDidMount = async () => {
    let project    = getItem('project')
    let project_id = project && project.id ? project.id : '';

    if (project_id) {
      let project_types_res = await ProjectFunctions.get_project_types(project_id);
      let project_types     = project_types_res && project_types_res.success && project_types_res.project_types ? project_types_res.project_types : {};
      setItem('project_types', project_types);
      this.populate_types(project_types);
    }
    if (project) {
      this.setState({ project: project });
    }
  }

  render() {
    return (
      <div>
        <Header navigate={this.props.navigate} project_name={this.state.proejct} />
        <div className='phases-screen-container'>
          <div className='main-pane main-container-sections'>
            <NavigationPane tab='settings' navigate={this.props.navigate} />
            <div className='settings-list-container'>
              <TypesPane title='Task Types'             locked={this.is_locked()} changeAction={ (key, new_values) => { this.changeAction(key, new_values) } } saveAction={ (key, arr, save_success, save_failure) => { this.saveAction(key, arr, save_success, save_failure) } } types={this.state.task_types} og_types={this.state.og_task_types} arr_key={'task_types'} />
              <TypesPane title='Task Status Types'      locked={this.is_locked()} changeAction={ (key, new_values) => { this.changeAction(key, new_values) } } saveAction={ (key, arr, save_success, save_failure) => { this.saveAction(key, arr, save_success, save_failure) } } types={this.state.task_status_types} og_types={this.state.og_task_status_types} arr_key={'task_status_types'} />
              <TypesPane title='Team Member Role Types' locked={this.is_locked()} changeAction={ (key, new_values) => { this.changeAction(key, new_values) } } saveAction={ (key, arr, save_success, save_failure) => { this.saveAction(key, arr, save_success, save_failure) } } types={this.state.team_member_role_types} og_types={this.state.og_team_member_role_types} arr_key={'team_member_role_types'} />
              <SettingsProjectName project={this.state.project} loading={this.state.loading_save_project} projectNameChange={this.project_name_change_action} projectSaveAction={this.save_project_action} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  project_name_change_action = (project_name) => {
    let updated_project     = Object.assign({}, this.state.project);
    updated_project['name'] = project_name ? project_name : this.state.project.name;
    this.setState({ project: updated_project });
  }

  save_project_action = async () => {
    this.setState({ loading_save_project: true });

    let project         = this.state.project;
    let update_proj_res = await ProjectFunctions.update_project(project);
    let is_success      = update_proj_res && update_proj_res.success;
    let updated_project = update_proj_res && update_proj_res.project ? update_proj_res.project : this.state.project;
    if (is_success) {
      setItem('project', updated_project)
    }

    this.setState({ loading_save_project: false });
  }

  changeAction = (key, new_values) => {
    let changed_state  = {}
    let unique_array   = [...new Set(new_values)];
    changed_state[key] = unique_array;
    this.setState(changed_state)
  }

  saveAction = async (key, final_array, save_success, save_failure) => {
    let proj_types   = Object.assign({}, this.state.project_types);
    let unique_array = [...new Set(final_array)];
    proj_types[key]  = unique_array;
    let project_id   = proj_types && proj_types.project_id ? proj_types.project_id : '';

    if (project_id) {
      let save_types_res    = await ProjectFunctions.update_project_types(proj_types);
      let project_types_res = await ProjectFunctions.get_project_types(project_id);
      let is_success        = save_types_res    && save_types_res.success;
      let project_types     = project_types_res && project_types_res.success && project_types_res.project_types ? project_types_res.project_types : {};
      if (is_success) {
        setItem('project_types', project_types);
        this.populate_types(project_types);
        save_success();
      } else {
        save_failure();
      }
    } else {
      save_failure();
    }
  }

  populate_types = (project_types) => {
    let task_types             = project_types && project_types.task_types             ? project_types.task_types             : [];
    let task_status_types      = project_types && project_types.task_status_types      ? project_types.task_status_types      : [];
    let team_member_role_types = project_types && project_types.team_member_role_types ? project_types.team_member_role_types : [];

    this.setState({
      project_types: project_types,
      og_task_types: [ ...task_types ],
      og_task_status_types: [ ...task_status_types ],
      og_team_member_role_types: [ ...team_member_role_types ],
      task_types: task_types,
      task_status_types: task_status_types,
      team_member_role_types: team_member_role_types
    });
  }

  is_locked = () => {
    let project           = getItem('project')
    let project_id        = project && project.id ? project.id : '';
    let is_locked         = false;
    let all_member_access = getItem('member_access') || {};
    let project_access    = all_member_access[project_id];

    if (project_access === 'tasks' || project_access === 'phases') {
      is_locked = true;
    }

    return is_locked;
  }
}

export default SettingsScreen;
