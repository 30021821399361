import { setItem, getItem, clearStorage } from '../storage';
import { UtilityFunctions } from '../functions';
import axios from 'axios';

const sign_out = (on_completed) => {
  clearStorage();
  if (on_completed) {
    on_completed();
  }
}

const verify_access_token = async () => {
  let token = getItem('token');
  if (token) {
    let token_valid_data = await UtilityFunctions.get('/verifyValidToken');
    return token_valid_data;
  }
}

const get_logged_in_user = async () => {
  let user_data  = await UtilityFunctions.get('/getLoggedInUser');
  let user_name  = user_data && user_data.name  ? user_data.name  : '';
  let user_email = user_data && user_data.email ? user_data.email : '';
  setItem('name',  user_name);
  setItem('email', user_email);
  return user_data;
}

const update_logged_in_user = async (user_info) => {
  let user_data = await UtilityFunctions.post('/updateLoggedInUser', user_info);
  return user_data;
}

export default {
  get_logged_in_user,
  sign_out,
  update_logged_in_user,
  verify_access_token
}
