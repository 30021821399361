import '../css/screens/projects-screen.css';
import '../css/components/add-project.css';
import React, { Component }    from 'react';
import { Header, ProgressBar, ReceivedInvitations, Input, AddName, AddProject, Modal } from '../components';
import { setItem, getItem } from '../storage';
import { AuthFunctions, ProjectFunctions, TeamFunctions } from '../functions';
import Lottie from 'lottie-react';

class ProjectsScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      project_invites: [],
      invite_loading_data: { project_id: '', button: '' },
      display_type: 'projects',
      project_selected: false,
    };
  }

  componentDidMount = async () => {
    let token   = getItem('token');
    let user_id = getItem('user_id');
    let name    = getItem('name');

    this.get_user_projects();
    this.get_user_invites();

    if (!name) {
      this.setState({ display_type: 'add_name' })
    }
  }

  componentDidUpdate = () => {
    let selected_project = getItem('project');
    if (selected_project && selected_project.id) {
      this.props.navigate('/phases');
    }
  }

  render_loading_projects = () => {
    if (!this.state.loading_projects) { return null }
    return <div className='phases-screen-loading-container'>
      <Lottie animationData={require('../animations/phases-loading.json')} style={{ height: 70, width: 70 }} />
      <div className='phases-screen-loading-text'>Loading Projects</div>
      <Lottie animationData={require('../animations/line-loading.json')} style={{ width: 200, marginTop: -65 }} />
    </div>
  }

  render_my_projects = () => {
    if (this.state.loading_projects) { return null }
    let projects     = this.state.projects;
    let project_rows = projects.map((project, index) => {
      let project_id   = project.id;
      let project_name = project.name;
      return <div key={project_id} className='item-container' onClick={ () => { setItem('project', project); this.setState({ project_selected: !this.state.project_selected }) }}>
        <div className='item-title'>{ project_name }</div>
        { /*
        <div className='item-key-value-container'>
          <div className='item-key-value-row'><span>Team</span><span>2</span></div>
          <div className='item-key-value-row'><span>Phases</span><span>5</span></div>
          <div className='item-key-value-row'><span>Tasks</span><span>8</span></div>
        </div>
        <div className='item-progress-bar-container'>
          <ProgressBar width={'100%'} progress={'30%'} />
        </div>
        */ }
      </div>
    }).filter((row) => { return row });

    return <div className='projects-list-container'>
      { project_rows }
    </div>
  }

  render_enter_name_section = () => {
    return (
      <div>
        <Header navigate={this.props.navigate} />
        <div className='projects-fullscreen'>
          <div className='projects-center-container'>
            <div className='update-profile-title'>UPDATE YOUR PROFILE</div>
            <AddName />
          </div>
        </div>
      </div>
    );
  }

  render() {
    let display_invites = this.state.project_invites && this.state.project_invites.length > 0 ? true : false;

    return (
      <div>
        <Header navigate={this.props.navigate} name={this.state.name} />
        <Modal display={this.state.display_type === 'new_project'}
               title={'New Project'}
               subtitle={'Enter Project Name'}
               onClose={ () => { this.setState({ display_type: 'projects' }) }}>
          <AddProject onSuccess={ () => { this.add_project_success() }} />
        </Modal>
        <Modal display={this.state.display_type === 'add_name'}
               locked={true}
               title={'Add Name'}
               subtitle={'Add a name to your profile'}
               onClose={ () => { this.setState({ display_type: 'projects' }) }}>
          <AddName onSuccess={ () => { this.setState({ display_type: 'projects' }) }}/>
        </Modal>
        <div className='screen-container'>
          <div className='main-pane'>
            <div className='section-container'>
              <div className='section-title'>
                <span className='section-title-text'>MY PROJECTS</span>
                <span className='fa-solid fa-circle-plus new-project-button' onClick={ () => {
                  this.setState({ display_type: 'new_project' })
                }}/>
              </div>
              <div>{ this.render_loading_projects() }</div>
              <div>{ this.render_my_projects()      }</div>
            </div>
          </div>
          <div className='right-pane'>
            { display_invites ? <div className='section-title'><span>INVITATIONS</span></div> : null }
            { display_invites ? <ReceivedInvitations invitations={ this.state.project_invites } loadingData={this.state.invite_loading_data} respondAction={this.invite_respond_invite} /> : null }
          </div>
        </div>
      </div>
    );
  }

  invite_respond_invite = async (invite_data) => {
    let load_button = invite_data && invite_data.accepted ? 'accept'  : '';
        load_button = invite_data && invite_data.declined ? 'decline' : load_button;
    this.setState({ invite_loading_data: { project_id: invite_data.project_id, button: load_button } });

    let invite_respond_res = await TeamFunctions.process_invite(invite_data);
    let is_success         = invite_respond_res && invite_respond_res.success ? true : false;

    this.get_user_invites();

    this.setState({ invite_loading_data: { project_id: '', button: '' } });
  }

  add_project_success = async () => {
    this.get_user_projects();
    this.setState({ display_type: 'projects' });
  }

  get_user_invites = async () => {
    let invites_data = await TeamFunctions.get_received_invites();
    let invites      = invites_data && invites_data.success && invites_data.invites ? invites_data.invites : [];
    this.setState({ project_invites: invites });
  }

  get_user_projects = async () => {
    this.setState({ loading_projects: true })
    let projects_data = await ProjectFunctions.get_user_projects();
    let projects      = projects_data && projects_data.success && projects_data.projects      ? projects_data.projects      : [];
    let member_access = projects_data && projects_data.success && projects_data.member_access ? projects_data.member_access : [];

    setItem('member_access', member_access);

    this.setState({ projects: projects, loading_projects: false });
  }
}

export default ProjectsScreen;
