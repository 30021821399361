import React, { Component } from 'react';
import '../css/components/checkbox.css';

class Checkbox extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  checkbox_clicked = () => {
    if (this.props.locked === true ) {
      return;
    }

    this.props.onClick()
  }

  render() {
    let is_checked      = this.props.checked === true ? true : false;
    let is_locked       = this.props.locked  === true ? true : false;
    let container_class = is_checked ? 'checkbox-container-selected' : 'checkbox-container';
    let icon_class      = 'fa-solid fa-check checkbox-check-icon';

    if (is_locked) {
      container_class = is_checked ? 'checkbox-container-locked' : 'checkbox-container-locked-unchecked';
    }

    return (
      <div className={ container_class } onClick={ () => { this.checkbox_clicked() }}>
        <span className={ 'fa-solid fa-check checkbox-check-icon' }></span>
      </div>
    );
  }
}

export default Checkbox;
