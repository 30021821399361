import React, { Component } from 'react';
import '../css/components/input.css';

class Input extends Component {

  render() {
    let value       = this.props.value       || '';
    let placeholder = this.props.placeholder || '';
    let is_locked   = this.props.locked === true ? true : false;
    let class_name  = 'default-input';
        class_name  = this.props.className ? 'default-input' + ' ' + this.props.className : class_name;

    return (
      <input type="text"
             value={value}
             placeholder={placeholder}
             className={ class_name }
             disabled={ is_locked }
             onChange={ (event) => {
               if (this.props.onChange && event && event.target && event.target.value) {
                 this.props.onChange(event.target.value);
               }
               if (this.props.onChange && event && event.target && event.target.value === '') {
                 this.props.onChange('');
               }
             }} />
    );
  }
}

export default Input;
