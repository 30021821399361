import React, { Component } from 'react';
import '../css/components/switch.css';

class Switch extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  switch_clicked = () => {
    this.props.onClick()
  }

  render() {
    let is_on           = this.props.on      === true ? true : false;
    let container_class = is_on ? 'switch-container-on' : 'switch-container';

    return (
      <div className={ container_class } onClick={ () => { this.switch_clicked() }}>
        <div className='swith-dot' />
      </div>
    );
  }
}

export default Switch;
