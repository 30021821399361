import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDG05U4jM833hqnoiAGFT33GQGtl7Gv5oI",
  authDomain: "drublo.firebaseapp.com",
  projectId: "drublo",
  storageBucket: "drublo.appspot.com",
  messagingSenderId: "754983990076",
  appId: "1:754983990076:web:1c9dfb36ab3092f3caa439",
  measurementId: "G-C1QML0DD5X"
};

// Initialize Firebase
let app  = initializeApp(firebaseConfig);
let auth = getAuth(app);
let db   = getFirestore(app);
let provider = new GoogleAuthProvider();

export {
  app,
  provider,
  auth,
  db,
  signInWithPopup
}
