import { setItem, getItem, clearStorage } from '../storage';
import axios from 'axios';

const get_base_url = () => {
  let base_url = 'https://us-central1-drublo.cloudfunctions.net';
  // let base_url = 'http://localhost:5001/drublo/us-central1';
  return base_url;
}

const get = async (path) => {
  try {
    let token = getItem('token');
    let request_url    = get_base_url() + path;
    let request_header = { headers: {  'Authorization': "Bearer " + token, 'Content-Type': 'application/json' } }
    let response = await axios.get(request_url, request_header);
    return response.data;
  } catch (error) {
    let is_logged_out = error && error.response && error.response.data && error.response.data === 'User Session Expired' ? true : false;
    if (is_logged_out) {
      console.log('User Session Expired');
      clearStorage();
    } else {
      console.error(`Error fetching data from ${path}`, error);
      throw error;
    }
  }
}

const post = async (path, data) => {
  try {
    let token = getItem('token');
    let request_url    = get_base_url() + path;
    let request_header = { headers: {  'Authorization': "Bearer " + token, 'Content-Type': 'application/json' } }
    let response = await axios.post(request_url, JSON.stringify(data), request_header);
    return response.data;
  } catch (error) {
    let is_logged_out = error && error.response && error.response.data && error.response.data === 'User Session Expired' ? true : false;
    if (is_logged_out) {
      console.log('User Session Expired');
      clearStorage();
    } else {
      console.error(`Error fetching data from ${path}`, error);
      throw error;
    }
  }
}

export default {
  get,
  get_base_url,
  post
}
