import React, { Component } from 'react';
import '../css/components/progress-bar.css';

class ProgressBar extends Component {
  render() {
    let { width, progress } = this.props;
    let is_completed        = progress === '100%';
    let progress_class      = is_completed ? 'progress-indicator-completed' : 'progress-indicator';

    return (
      <div className="progress-bar" style={{ width: width }}>
        <div className={ progress_class } style={{ width: progress }}>

        </div>
      </div>
    );
  }
}

export default ProgressBar;
