import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import '../css/components/navigation-pane.css';

class NavigationPane extends Component {

  phases_clicked = () => {
    let is_phases_selected = this.props.tab === 'phases';
    if (!is_phases_selected) {
      this.props.navigate('/phases');
    }
  }

  team_clicked = () => {
    let is_team_selected = this.props.tab === 'team';
    if (!is_team_selected) {
      this.props.navigate('/team');
    }
  }

  settings_clicked = () => {
    let is_settings_selected = this.props.tab === 'settings';
    if (!is_settings_selected) {
      this.props.navigate('/settings');
    }
  }

  switch_clicked = () => {
    setItem('project', '')
    this.props.navigate('/projects');
  }

  render() {

    let is_phases_selected   = this.props.tab === 'phases';
    let is_team_selected     = this.props.tab === 'team';
    let is_settings_selected = this.props.tab === 'settings';

    let phases_icon_class    = is_phases_selected   ? 'navigation-button-icon fa-solid fa-table-list navigation-phases-selected-icon' : 'navigation-button-icon fa-solid fa-table-list';
    let phases_title_class   = is_phases_selected   ? 'navigation-button-title selected-title' : 'navigation-button-title';

    let team_icon_class      = is_team_selected     ? 'navigation-button-icon fa-solid fa-people-group navigation-team-selected-icon' : 'navigation-button-icon fa-solid fa-people-group';
    let team_title_class     = is_team_selected     ? 'navigation-button-title selected-title' : 'navigation-button-title';

    let settings_icon_class  = is_settings_selected ? 'navigation-button-icon fa-solid fa-gear navigation-settings-selected-icon' : 'navigation-button-icon fa-solid fa-gear';
    let settings_title_class = is_settings_selected ? 'navigation-button-title selected-title' : 'navigation-button-title';

    return (
      <div className='navigation-container'>
        <div className='navigation-button-container' onClick={ () => { this.phases_clicked() }}>
          <span className={ phases_icon_class  }/>
          <span className={ phases_title_class }>Phases</span>
        </div>
        <div className='navigation-button-container' onClick={ () => { this.team_clicked() }}>
          <span className={ team_icon_class  }/>
          <span className={ team_title_class }>Team</span>
        </div>
        <div className='navigation-button-container' onClick={ () => { this.settings_clicked() }}>
          <span className={ settings_icon_class  }/>
          <span className={ settings_title_class }>Settings</span>
        </div>
        <div className='navigation-button-container' onClick={ () => { this.switch_clicked() }}>
          <span className='navigation-button-icon fa-solid fa-repeat'/>
          <span className='navigation-button-title'>Swtich Projects</span>
        </div>
      </div>
    );
  }
}

export default NavigationPane;
