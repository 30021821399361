import React, { Component } from 'react';
import { StringUtils } from '../utils';
import '../css/components/dropdown.css';

class Dropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_menu_open: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handle_outside_click);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handle_outside_click);
  }

  handle_outside_click = (e) => {
    if (this.dropdownRef && !this.dropdownRef.contains(e.target)) {
      this.setState({ is_menu_open: false });
    }
  }

  selection_action = (option) => {
    this.props.onSelect(option);
    this.setState({ is_menu_open: false });
  }

  render_options_list = () => {
    if (this.state.is_menu_open === false) { return null }

    let option_style = { width:  this.props.width  ? this.props.width + 20 : '' }
    let options_arr  = this.props.options || [];
    let options_rows = options_arr.map((option) => {
      let option_display_str = StringUtils.keyToDisplayString(option);
      return <div className="dropdown-item"
                  onClick={ () => { this.selection_action(option) } }>{ option_display_str }</div>
    })

    return <div className="dropdown-list" style={{ ...option_style }}>
      { options_rows }
    </div>
  }

  render() {
    let class_name = this.props.className || 'default-dropdown';
    let style      = this.props.styles;
    let is_open    = this.state.is_menu_open;
    let placeholder= this.props.placeholder;
    let label      = this.props.label;
        label      = label ? label : placeholder;
    let btn_style  = { borderBottomRightRadius: this.state.is_menu_open ? 0 : 4, borderBottomLeftRadius: this.state.is_menu_open ? 0 : 4 }
    let is_locked  = this.props.locked;
    let btn_class  = is_locked ? 'dropdown-btn-locked' : 'dropdown-btn'

    btn_style = {
      color: !this.props.label  ? '#696969' : '#4c4c4c',
      width:  this.props.width  ? this.props.width : '',
      height: this.props.height ? this.props.height : '',
      ...btn_style
    }

    return (
      <div className="custom-dropdown" style={{ ...style }} ref={el => (this.dropdownRef = el)}>
        <div className={ btn_class }
             style={{ ...btn_style }}
             onClick={ () => {
               if (is_locked) { return }
               this.setState({ is_menu_open: !this.state.is_menu_open })
             }}>{ label }</div>
          { this.render_options_list() }
      </div>
    );
  }
}

export default Dropdown;
