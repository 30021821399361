import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { AuthFunctions }    from '../functions';
import { Input, Button }    from '../components';
import '../css/components/add-name.css';

class AddName extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: ''
    };
  }

  componentDidMount = async () => {
    let name = getItem('name');
        name = name || '';
    this.setState({ name: name });
  }

  save_profile_action = async () => {
    if (!this.state.name) { return }
    this.setState({ loading_save_name: true });

    let name        = this.state.name;
    let request_obj = { name: name };

    let user_update = await AuthFunctions.update_logged_in_user(request_obj);
    let user_data   = await AuthFunctions.get_logged_in_user();

    name = getItem('name');

    if (name && this.props.onSuccess) {
      this.props.onSuccess();
    }

    this.setState({ loading_save_name: false, name: name });
  }

  render() {

    return (
      <div className='add-name-container'>
        <Input  className='add-name-input'  value={this.state.name} placeholder='Enter your name' onChange={ (name) => { this.setState({ name: name }) }} />
        <Button className='add-name-button' loading={this.state.loading_save_name} title='Save Name' onClick={ () => { this.save_profile_action() }} />
      </div>
    );
  }
}

export default AddName;
