import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { AuthFunctions }    from '../functions';
import { Input, Button, Line } from '../components';
import '../css/components/user-profile.css';

class UserProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      loading_save_profile: false,
      sign_out_flag: false,
    };
  }

  componentDidMount = async () => {
    let name  = getItem('name');
        name  = name || '';
    let email = getItem('email');
    this.setState({ name: name, email: email });
  }

  save_profile_action = async () => {
    if (!this.state.name) { return }
    this.setState({ loading_save_profile: true });

    let name        = this.state.name;
    let request_obj = { name: name };

    let user_update = await AuthFunctions.update_logged_in_user(request_obj);
    let user_data   = await AuthFunctions.get_logged_in_user();

    name = getItem('name');

    this.setState({ loading_save_profile: false, name: name });
  }

  render() {
    return (
      <div className='user-profile-container'>
        <div className='user-profile-name-label'>Name</div>
        <Input className='user-profile-name-input' value={this.state.name} onChange={ (name) => { this.setState({ name: name }) }} />
        <Button title='Save Profile' loading={this.state.loading_save_profile} onClick={ () => { this.save_profile_action() }} className='user-profile-save-button' />
        <Line />
        <div className='user-profile-signed-in-title'>Signed In As</div>
        <div className='user-profile-signed-in-email'>{ this.state.email }</div>
        <Button title='Sign Out' onClick={ () => { AuthFunctions.sign_out(this.props.on_sign_out_completed) }} className='user-profile-sign-out-button' />
      </div>
    );
  }
}

export default UserProfile;
