import { setItem, getItem } from '../storage';
import { UtilityFunctions } from '../functions';
import axios from 'axios';

const create_project_invite = async (invite_data) => {
  let created_project_invite = await UtilityFunctions.post('/createProjectInvite', invite_data);
  return created_project_invite;
}

const cancel_project_invite = async (invite_data) => {
  let cancelled_project_invite = await UtilityFunctions.post('/cancelProjectInvite', invite_data);
  return cancelled_project_invite;
}

const get_sent_invites = async (project_id) => {
  let retrieved_project_invite = await UtilityFunctions.post('/getProjectInvites', { project_id: project_id });
  return retrieved_project_invite;
}

const get_received_invites = async () => {
  let email = getItem('email');
  let retrieved_invite = await UtilityFunctions.post('/getUserInvites', { email: email });
  return retrieved_invite;
}

const process_invite = async (invite_data) => {
  let retrieved_invite = await UtilityFunctions.post('/processInvite', invite_data);
  return retrieved_invite;
}

const get_team_members = async (project_id) => {
  let members_data = await UtilityFunctions.post('/getProjectMembers', { project_id: project_id });
  return members_data;
}

const update_team_member = async (member) => {
  let udpated_member_data = await UtilityFunctions.post('/updateProjectMember', member);
  return udpated_member_data;
}

const get_internal_users = async (project_id) => {
  let udpated_member_data = await UtilityFunctions.post('/getInternalUsers', { project_id: project_id });
  return udpated_member_data;
}

const update_internal_users = async (internal_users) => {
  let udpated_member_data = await UtilityFunctions.post('/updateInternalUsers', internal_users);
  return udpated_member_data;
}

export default {
  cancel_project_invite,
  create_project_invite,
  get_internal_users,
  get_received_invites,
  get_sent_invites,
  get_team_members,
  process_invite,
  update_internal_users,
  update_team_member
}
