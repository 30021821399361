import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { StringUtils }      from '../utils';
import { Line, ProgressBar, Checkbox, Dropdown } from '../components';
import Lottie from 'lottie-react';
import '../css/components/team-pane.css';

class TeamPane extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  role_change_action = (member, new_role) => {
    let existing_members = this.props.members || [];
    let new_members_arr  = [];
    let changed_member   = {};

    existing_members.forEach((mem, i) => {
      let member_obj = Object.assign({}, mem)
      if (member && member.user_id && member.user_id === mem.user_id) {
        member_obj['role'] = new_role;
        changed_member     = member_obj;
        new_members_arr.push(member_obj);
      } else {
        new_members_arr.push(member_obj);
      }
    });

    let change_obj = { new_array: new_members_arr, member: changed_member }

    this.props.updateMembers(change_obj);
  }

  render_member_rows = () => {
    let members      = this.props.members      || [];
    let member_types = this.props.member_types || [];
    let member_rows  = members.map((member, idx) => {
      let name      = member.name;
      let acc_lvl   = member.access_level;
      let role      = StringUtils.keyToDisplayString(member.role);
      let acc_obj   = this.get_access_checks(acc_lvl);
      let is_locked = member.role === 'owner';
          is_locked = this.determine_access_lock() || is_locked;
      let is_last   = false;
      return <div className='flex-container-column'>
        <div className='team-member-row-container'>
          <div className="team-member-row">
            <div className="team-member-row-name">{ name }</div>
            <div className="team-member-row-type">
              <Dropdown label={role} options={ member_types } locked={is_locked} onSelect={ (selected) => { this.role_change_action(member, selected) }} />
            </div>
            <div className="team-member-row-role"><Checkbox checked={ acc_obj.admin   } locked={is_locked} onClick={ () => { this.access_check_action(member, acc_lvl, 'admin')   }} /></div>
            <div className="team-member-row-role"><Checkbox checked={ acc_obj.manager } locked={is_locked} onClick={ () => { this.access_check_action(member, acc_lvl, 'manager') }}/></div>
            <div className="team-member-row-role"><Checkbox checked={ acc_obj.phases  } locked={is_locked} onClick={ () => { this.access_check_action(member, acc_lvl, 'phases')  }}/></div>
            <div className="team-member-row-role"><Checkbox checked={ acc_obj.tasks   } locked={is_locked} onClick={ () => { this.access_check_action(member, acc_lvl, 'tasks')   }}/></div>
          </div>
        </div>
      </div>
    })

    let display_loading = member_rows.length === 0;

    return <div className='flex-container-column'>
      { display_loading ? this.render_members_loading() : null }
      { member_rows }
    </div>
  }

  render_members_loading = () => {
    return <div className='team-pane-team-loading-container'>
      <div className='team-pane-team-loading-text'>Loading Team</div>
      <Lottie animationData={require('../animations/line-loading.json')} style={{ width: 200, marginTop: -65 }} />
    </div>
  }

  render() {
    return <div className='team-pane-cards-container'>
      <div className='flex-container-column'>
        <div className='team-card'>
          <div className="team-card-header">
            <div className="title">Project Team</div>
          </div>

          <Line className='team-seperator-line' />

          <div className="table-header">
            <div className="team-member-name">NAME</div>
            <div className="team-member-role">ROLE</div>
            <div className="team-role-header">ADMIN</div>
            <div className="team-role-header">MANAGER</div>
            <div className="team-role-header">PHASES</div>
            <div className="team-role-header">TASKS</div>
          </div>

          <Line className='team-seperator-line' />

          { this.render_member_rows() }

        </div>
      </div>
    </div>
  }

  access_check_action = (member, current_access, selected_access) => {
    let new_access_level = selected_access;
    let existing_members = this.props.members || [];
    let new_members_arr  = [];
    let changed_member   = {};

    if (current_access === selected_access) {
      new_access_level = this.get_processed_level(current_access);
    }

    existing_members.forEach((mem, i) => {
      let member_obj = Object.assign({}, mem)
      if (member && member.user_id && member.user_id === mem.user_id) {
        member_obj['access_level'] = new_access_level;
        changed_member     = member_obj;
        new_members_arr.push(member_obj);
      } else {
        new_members_arr.push(member_obj);
      }
    });

    let change_obj = { new_array: new_members_arr, member: changed_member }

    this.props.updateMembers(change_obj);
  }

  get_access_checks = (current_role) => {
    let checked_obj = {};
    checked_obj['admin']   = current_role === 'admin' ? true : false;
    checked_obj['manager'] = current_role === 'manager' || checked_obj['admin']   ? true : false;
    checked_obj['phases']  = current_role === 'phases'  || checked_obj['manager'] ? true : false;
    checked_obj['tasks']   = current_role === 'tasks'   || checked_obj['phases']  ? true : false;
    return checked_obj;
  }

  get_processed_level = (current_access) => {
    if (current_access === 'tasks') { return 'tasks' }
    let access_level_keys = { admin: 0, manager: 1, phases: 2, tasks: 3 }
    let access_level_arr  = Object.keys(access_level_keys);
    let new_access_index  = access_level_keys[current_access] + 1;
    return access_level_arr[new_access_index]
  }

  determine_access_lock = () => {
    let project           = getItem('project')
    let project_id        = project && project.id ? project.id : '';
    let is_locked         = false;
    let all_member_access = getItem('member_access') || {};
    let project_access    = all_member_access[project_id];

    if (project_access === 'tasks' || project_access === 'phases') {
      is_locked = true;
    }

    return is_locked;
  }
}

export default TeamPane;
