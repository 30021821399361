import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { ProjectFunctions } from '../functions';
import { Button, Line, Input } from '../components';
import '../css/components/completed-phases.css';

class CompletedPhases extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = () => {

  }

  completed_phase_selected = (phase) => {
    if (this.props.completed_phase_action) {
      this.props.completed_phase_action(phase)
    }
  }

  render_completed_phase_list = () => {
    let completed_phases = this.props.phases || [];
    let phase_rows = completed_phases.map((phase) => {
      let name = phase.name;
      return <div className='completed-phase-row' onClick={ () => { this.completed_phase_selected(phase) }}>
        <div style={{ fontSize: 13 }}>{ name }</div>
      </div>
    })

    return <div style={{ marginTop: 10 }}>
      { phase_rows }
    </div>
  }

  render() {
    return (
      <div className='completed-pane-container'>
        <div className="title">{ 'Completed Phases' }</div>
        { this.render_completed_phase_list() }
      </div>
    );
  }

}

export default CompletedPhases;
