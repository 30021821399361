import React, { Component }  from 'react';
import { Line, ProgressBar,Button } from '../components';
import { setItem, getItem }  from '../storage';
import { StringUtils } from '../utils';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Lottie from 'lottie-react';
import '../css/components/phase-pane.css';

class PhasePane extends Component {

  render_phase_cards = () => {
    let phases     = this.props.phases || [];
    let phase_rows = phases.map((phase, ind) => {
      let bottom_padding = ind === phases.length -1 ? null : <div style={{ height: 15 }} />;
      return <div className='flex-container-column'>
        { this.render_phase_card(phase) }
        { bottom_padding }
      </div>
    })

    return <div>
      { this.render_no_phases_section(phase_rows) }
      { phase_rows }
    </div>
  }

  render_no_phases_section = (phase_rows) => {
    let is_initail_lodaing = this.props.initial_loading;
    let is_empty           = phase_rows.length === 0;

    if (!is_initail_lodaing && is_empty) {
      return <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f4f4f4', alignItems: 'center', justifyContent: 'center', height: 200, borderRadius: 12 }}>
        <div style={{ color: '#4c4c4c', marginBottom: 2,  fontSize: 17, fontWeight: 'bold' }}>Get Started</div>
        <div style={{ color: 'grey', marginBottom: 12, fontSize: 15 }}>Add the first phase of your project</div>
        <div className="add-first-task-button" onClick={ () => { this.props.onRightPaneChange({ section_name: 'new_phase' }) }}>
          <span className='fa-solid fa-circle-plus add-first-task-icon'></span>
          <div className="add-first-task-text">{ 'Add a Phase' }</div>
        </div>
      </div>
    }
  }

  onDragEnd = (result) => {
    let phase_id          = result.destination && result.destination.droppableId ? result.destination.droppableId : '';
    let task_id           = result.draggableId || '';
    let destination_index = result.destination && result.destination.index       ? result.destination.index       : '';

    if (phase_id && task_id && this.props.onTaskDragged) {
      this.props.onTaskDragged({ phase_id: phase_id, task_id: task_id, new_index: destination_index });
    }
  }

  render_phase_card = (phase) => {
    let phase_name = phase.name;
    let phase_id   = phase.id;
    let tasks      = phase.tasks || [];
        tasks      = this.access_filtered_tasks(tasks, phase.project_id);
    let all_complt = this.check_all_completed(tasks);
    let completed  = phase.status === 'completed';
    let card_class = completed ? 'card-completed-phase' : 'card';
    let load_move  = this.props.loading_complete_phase === true ? true : false;
    let hide_phase = this.determine_hide_phase(tasks, phase.project_id);
    let hide_move  = this.determine_hide_move(phase.project_id);
    let dis_com_bt = phase.status !== 'completed' && all_complt;
        dis_com_bt = hide_move ? false : dis_com_bt;
    let is_empty   = tasks && tasks.length === 0;

    if (hide_phase) { return null }

    return <div className='flex-container-column'>

      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className={ card_class }>
          <div className="phase-card-header">
            <div className="title">{ phase_name }</div>
            <div className='flex-container-row'>
              { dis_com_bt ? <div className="send-to-completed-container" onClick={ () => { this.props.complete_phase_action(phase) }}>
                {  load_move ? <Lottie animationData={require('../animations/white-spinner.json')} style={{ height: 13, width: 13, alignSelf: 'center', marginTop: -2 }} /> : null }
                { !load_move ? <div className="send-to-completed">{ 'Move To Completed' }</div> : null }
              </div> : null }
              <div className="details-container" onClick={ () => { this.props.onRightPaneChange({ section_name: 'phase_details', phase: phase }) }}>
                <div className="details">Details</div>
              </div>
            </div>
          </div>
          <Line className='phase-seperator-line' />
          <div className="table-header">
            <div className="task-name">TASK NAME</div>
            <div className="assignee">ASSIGNEE</div>
            <div className="type">TYPE</div>
            <div className="status">STATUS</div>
            <div className="progress">PROGRESS</div>
          </div>
          <Line className='phase-seperator-line' />

          <Droppable droppableId={phase_id}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>

                { tasks.map((task, ind) => { return this.render_task_row(task, ind, provided) }) }
                { is_empty ? this.render_empty_phase_message(phase.id, phase_name) : null }
                { provided.placeholder }
              </div>
            )}
          </Droppable>

        </div>
      </DragDropContext>

      { completed ? null : <div className="add-task-container">
        <div className="curve-container">
          <div className="cuver-white-container"></div>
        </div>
        <div className="add-task-button" onClick={ () => { this.props.onRightPaneChange({ section_name: 'new_task', phase_id: phase.id, phase_name: phase_name }) }}>
          <span className='fa-solid fa-circle-plus'></span>
        </div>
      </div> }

    </div>
  }

  render_empty_phase_message = (phase_id, phase_name) => {
    return <div className='add-first-task-container'>
      <div className="add-first-task-button" onClick={ () => { this.props.onRightPaneChange({ section_name: 'new_task', phase_id: phase_id, phase_name: phase_name }) }}>
        <span className='fa-solid fa-circle-plus add-first-task-icon'></span>
        <div className="add-first-task-text">{ 'Add a Task' }</div>
      </div>
    </div>
  }

  render_task_row = (task, index, provided) => {
    let name      = task.name;
    let assignee  = this.get_assignee_name(task.assignee_id);
    let type      = StringUtils.keyToDisplayString(task.type);
    let status    = StringUtils.keyToDisplayString(task.status);
    let estimated = task.estimated_hours;
    let completed = task.completed_hours;
    let percent   = task.track_hours ? parseInt(completed / estimated * 100) : task.progress;
        percent   = percent || 0;
    let per_str   = percent.toString() + '%';

    return <Draggable key={task.id} draggableId={task.id} index={index}>
      { (provided) => (
        <div className='phase-row-container' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <div className="task-progress" onClick={ () => { this.props.onRightPaneChange({ section_name:  'task_details', task: task }) }}>
            <div className="task-progress-row">
              <div className="task-progress-name">{ name }</div>
              <div className="task-progress-assignee">{ assignee }</div>
              <div className="task-progress-type">{ type }</div>
              <div className="task-progress-status">{ status }</div>
              <div className="task-progress-progress">
                <ProgressBar width={ 80 } progress={ per_str } />
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  }

  render_phase_buttons = () => {

    let hide_phase_buttons = this.determine_hide_phase_buttons();

    if (hide_phase_buttons) { return null }

    return <div className='phase-buttons-container'>
      <div className="phase-button-container"
           onClick={ () => {
             this.props.onRightPaneChange({ section_name: 'completed_phases' });
             this.props.onChangePhasesType('completed');
           }}>
        <span className="fa-solid fa-circle-check phase-button-icon"></span>
        <span className="phase-button-label">Completed Phases</span>
      </div>
      <div className='phase-button-separator'/>
      <div className="phase-button-container" onClick={ () => { this.props.onRightPaneChange({ section_name: 'new_phase' }) }}>
        <span className="fa-solid fa-circle-plus phase-button-icon"></span>
        <span className="phase-button-label">New Phase</span>
      </div>
    </div>
  }

  render_active_phases = () => {
    let is_completed_on = this.props.completed_phases_open;
    if (is_completed_on) { return null }

    return <div>
      { this.render_phase_cards()   }
      { this.render_phase_buttons() }
    </div>
  }

  render_completed_buttons = () => {
    return <div className='phase-buttons-container'>
      <div className="phase-button-container"
           onClick={ () => {
             this.props.onRightPaneChange({ section_name: '' });
             this.props.onChangePhasesType('active');
           }}>
        <span className="fa-solid fa-circle-arrow-left phase-button-icon"></span>
        <span className="phase-button-label">Back To Active Phases</span>
      </div>
    </div>
  }

  render_empty_completed = () => {

    return <div>
      <div className='empty-completed-phase-text'>Select a completed phase from the right pane</div>
      <Line />
    </div>
  }

  render_completed_phases = () => {
    let is_completed_on = this.props.completed_phases_open;
    let completed_phase = this.props.completed_phase_selected;
    let display_pane    = is_completed_on && completed_phase && completed_phase.id;
    let display_empty   = is_completed_on && (!completed_phase || !completed_phase.id);

    return <div>
      { display_empty   ? this.render_empty_completed()           : null }
      { display_pane    ? this.render_phase_card(completed_phase) : null }
      { is_completed_on ? this.render_completed_buttons()         : null }
    </div>
  }

  render() {
    return (
      <div className='phase-cards-container'>
        { this.render_active_phases()    }
        { this.render_completed_phases() }
      </div>
    );
  }

  get_assignee_name = (assignee_id) => {
    let name     = 'Unassigned'
    let members  = this.props.members;
    let internal = this.props.internal_users;

    members.forEach((member) => {
      if (member && member.user_id === assignee_id) {
        name = member.name;
      }
    });

    internal.forEach((internal_user, i) => {
      let with_prefix = 'internal_' + internal_user;
      if (with_prefix === assignee_id) {
        name = StringUtils.keyToDisplayString(with_prefix);
      }
    });

    return name;
  }

  check_all_completed = (tasks) => {
    let all_completed = true;

    tasks.forEach((task) => {
      if (task && task.status !== 'completed') {
        all_completed = false;
      }
    });

    if (tasks && tasks.length === 0) {
      all_completed = false;
    }

    return all_completed;
  }

  access_filtered_tasks = (tasks, project_id) => {
    let user_id           = getItem('user_id');
    let all_member_access = getItem('member_access') || {};
    let project_access    = all_member_access[project_id];
    let filtered_tasks    = [];

    tasks.forEach((task, i) => {
      if (project_access === 'tasks' && task.assignee_id === user_id) {
        filtered_tasks.push(task);
      }
    });

    if (project_access === 'admin') {
      filtered_tasks = [ ...tasks ];
    }

    return filtered_tasks;
  }

  determine_hide_phase = (tasks, project_id) => {
    let hide_phase        = false;
    let all_member_access = getItem('member_access') || {};
    let project_access    = all_member_access[project_id];

    if (project_access === 'tasks' && tasks.length === 0) {
      hide_phase = true;
    }

    return hide_phase;
  }

  determine_hide_move = (project_id) => {
    let hide_move         = false;
    let all_member_access = getItem('member_access') || {};
    let project_access    = all_member_access[project_id];

    if (project_access === 'tasks') {
      hide_move = true;
    }

    return hide_move;
  }

  determine_hide_phase_buttons = () => {
    let project           = getItem('project')
    let project_id        = project && project.id ? project.id : '';
    let hide_buttons      = false;
    let all_member_access = getItem('member_access') || {};
    let project_access    = all_member_access[project_id];

    if (project_access === 'tasks') {
      hide_buttons = true;
    }

    return hide_buttons;
  }

}

export default PhasePane;
