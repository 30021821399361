import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { StringUtils }      from '../utils';
import { ProjectFunctions } from '../functions';
import { Button, Line, Input, Dropdown, Switch } from '../components';
import '../css/components/task-details.css';

class TaskDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      task_id: '',
      phase_id: '',
      name: '',
      assignee_id: '',
      type: '',
      status: '',
      progress: 0,
      estimated_hours: 0,
      completed_hours: 0,
      display_editable_fields: false,
      track_hours: false,
      comments: [],
      comments_pulled: false,
      comment_text: ''
    };
  }

  configure_state = async () => {
    let data        = this.props.data;
    let passed_task = data.task || {};
    let task_id     = passed_task && passed_task.id;
    let tasks_dict  = this.props.tasks_dict;
    let task        = tasks_dict[task_id]

    if (task_id && this.state.task_id !== task_id) {
      let comments_res = await ProjectFunctions.get_comments({ task_id: task.id });
      let comments     = comments_res && comments_res.success && comments_res.comments ? comments_res.comments : [];
      this.setState({
        task_id: task.id,
        phase_id: task.phase_id,
        name: task.name,
        assignee_id: task.assignee_id,
        type: task.type,
        status: task.status,
        progress: task.progress || 0,
        track_hours: task.track_hours || false,
        estimated_hours: task.estimated_hours,
        completed_hours: task.completed_hours,
        display_editable_fields: false,
        comments: comments
      })
    }
  }

  componentDidMount = () => {
    this.configure_state();
  }

  componentDidUpdate = () => {
    this.configure_state();
  }

  render_editable_fields = () => {

    if (!this.state.display_editable_fields) { return <div className='task-edit-section-buffer' /> }

    let assignee_label   = this.get_assignee_label();
    let type_label       = this.get_type_label();
    let status_label     = this.get_status_label();

    let assignee_options = this.get_assignee_options();
    let type_options     = this.get_type_options();
    let status_options   = this.get_status_options();

    return <div className='flex-container-column'>
      <div className='task-edit-section-buffer' />
      <div className='task-details-input-row'>
        <div className='task-details-input-key'>Name</div>
        <Input className='task-details-input' value={this.state.name} placeholder='Enter Name' onChange={ (name) => { this.setState({ name: name }) }} />
      </div>
      <div className='task-details-input-row'>
        <div className='task-details-input-key'>Assignee</div>
        <Dropdown label={assignee_label} placeholder='Select Assignee' height={23} width={190} options={ assignee_options } onSelect={ (selected) => { this.assignee_selected(selected) }} />
      </div>
      <div className='task-details-input-row'>
        <div className='task-details-input-key'>Type</div>
        <Dropdown label={type_label} placeholder='Select Type' height={23} width={190} options={ type_options } onSelect={ (selected) => { this.type_selected(selected) }} />
      </div>
      <div className='task-details-input-row'>
        <div className='task-details-input-key'>Status</div>
        <Dropdown label={status_label} placeholder='Select Status' height={23} width={190} options={ status_options } onSelect={ (selected) => { this.status_selected(selected) }} />
      </div>
      <div className='task-details-input-row'>
        <div className='task-details-input-key'>Track Hours</div>
        <div className='task-details-track-hours-switch-container'>
          <Switch on={this.state.track_hours} onClick={ () => { this.setState({ track_hours: !this.state.track_hours }) }} />
        </div>
      </div>
    </div>
  }

  render_comments_section = () => {
    let user_id      = getItem('user_id');
    let comments     = this.state.comments;
    let comment_rows = comments.map((comment) => {
      let is_loading = this.state.deleting_comment_id === comment.id;
      let delete_str = is_loading ? 'Deleting...' : 'Delete';
      let show_del   = comment.user_id === user_id ? true : false;
      return <div className='comment-container'>
        <div className='comment-sender-container'>
          <div className='comment-sender-name'>{ comment.name }</div>
          { show_del ? <div className='comment-delete-text' onClick={ () => { this.delete_comment_action(comment.id) }}>{ delete_str }</div> : null }
        </div>
        <div className='comment-text'>{ comment.text }</div>
      </div>
    })

    return <div className='comments-section'>
      <div className='title task-details-title'>Comments</div>

      { comment_rows }

      <div className='comment-textarea-container'>
        <textarea
          className='comment-input-textarea'
          value={this.state.comment_text}
          onChange={ (event) => {
            let text = event && event.target && event.target.value;
            this.setState({ comment_text: text });
          }}
          rows={3}
        />
      </div>
      <div className='comment-add-button-container'>
        <Button className='comment-add-button' title='Add Comment' loading={ this.state.loading_add_comment } onClick={ () => { this.add_comment() }}/>
      </div>
    </div>
  }

  render_status_field = () => {

    if (this.state.display_editable_fields) { return null }

    let status_label     = this.get_status_label();
    let status_options   = this.get_status_options();

    return <div>
      <div className='task-details-input-row'>
        <div className='task-details-status-key'>Status</div>
        <Dropdown label={status_label} placeholder='Select Status' height={23} width={150} options={ status_options } onSelect={ (selected) => { this.status_selected(selected) }} />
      </div>
    </div>
  }

  render_percent_field = () => {
    if (this.state.track_hours === true) { return null }

    return <div className='task-details-input-row'>
      <div className='task-details-input-wide-key'>Progress</div>
      <div className='task-details-hours-container'>
        <span className='fa-solid fa-circle-minus task-details-hours-button' onClick={ () => { this.decrement_progress_percent() }}></span>
        <span className='task-details-progress-number'>{ this.state.progress + ' %' }</span>
        <span className='fa-solid fa-circle-plus  task-details-hours-button' onClick={ () => { this.increment_progress_percent() }}></span>
      </div>
    </div>
  }

  render_hours_fields = () => {
    if (!this.state.track_hours) { return null }

    return <div>
      <div className='task-details-input-row'>
        <div className='task-details-input-wide-key'>Estimated Hours</div>
        <div className='task-details-hours-container'>
          <span className='fa-solid fa-circle-minus task-details-hours-button' onClick={ () => { this.decrement_estimated_hours() }}></span>
          <span className='task-details-hours-number'>{ this.state.estimated_hours }</span>
          <span className='fa-solid fa-circle-plus  task-details-hours-button' onClick={ () => { this.increment_estimated_hours() }}></span>
        </div>
      </div>
      <div className='task-details-input-row'>
        <div className='task-details-input-wide-key'>Completed Hours</div>
        <div className='task-details-hours-container'>
          <span className='fa-solid fa-circle-minus task-details-hours-button' onClick={ () => { this.decrement_completed_hours() }}></span>
          <span className='task-details-hours-number'>{ this.state.completed_hours }</span>
          <span className='fa-solid fa-circle-plus  task-details-hours-button' onClick={ () => { this.increment_completed_hours() }}></span>
        </div>
      </div>
    </div>
  }

  render_close_button = () => {
    if (!this.props.display_close) { return null }
    return <div className='flex-container-row' onClick={ () => { this.props.close_action() }}>
      <div className='flex-one'></div>
      <div className='right-pane-close-curve-containers'>
        <div className='right-pane-close-curve-left' />
      </div>
      <div className='right-pane-close-button'>
        <span className='fa-solid fa-circle-xmark modal-close-button right-pane-close-button-icon' onClick={ () => { this.onClose() }}/>
        <div className='right-pane-close-button-text'>Close</div>
      </div>
      <div className='right-pane-close-curve-containers'>
        <div className='right-pane-close-curve-right' />
      </div>
      <div className='right-pane-close-button-right-margin'></div>
    </div>
  }

  render() {

    let button_title = 'Save Changes';
    let display_save = this.determine_display_save();

    return (
      <div>
        { this.render_close_button() }
        <div className='right-pane-container'>
          <div className='task-details-title-container'>
            <div className='title task-details-title'>Task</div>
            <div className='task-details-edit-button' onClick={ () => { this.open_edit_action() }}>{ this.state.display_editable_fields ? 'Cancel' : 'Edit' }</div>
          </div>
          <div className='task-details-subtitle'>{ this.state.name }</div>

          { this.render_editable_fields() }
          { this.render_status_field()    }
          { this.render_percent_field()   }
          { this.render_hours_fields()    }

          { display_save ? <div className='comment-add-button-container'>
            <Button className='comment-add-button' loading={ this.props.loading } onClick={ () => { this.save_task_action() }} title={ button_title } />
          </div> : null }

          <Line />

          { this.render_comments_section() }

        </div>
      </div>
    );
  }

  determine_display_save = () => {
    let display = false;

    let data        = this.props.data;
    let passed_task = data.task || {};
    let task_id     = passed_task && passed_task.id;
    let tasks_dict  = this.props.tasks_dict;
    let task        = tasks_dict[task_id]

    let edit_hidden       = this.state.display_editable_fields;
    let status_changed    = task.status          !== StringUtils.displayStringToKey(this.state.status);
    let estimated_changed = task.estimated_hours !== this.state.estimated_hours;
    let passed_changed    = task.completed_hours !== this.state.completed_hours;
    let progress_changed  = task.progress        !== this.state.progress;

    display = edit_hidden || status_changed || estimated_changed || passed_changed || progress_changed;

    return display;
  }

  add_comment = async () => {
    if (!this.state.comment_text) { return }
    this.setState({ loading_add_comment: true })

    let user_id = getItem('user_id');
    let task_id = this.state.task_id;
    let text    = this.state.comment_text;
    let comment = {
      task_id: task_id,
      user_id: user_id,
      text: text
    }

    let add_res      = await ProjectFunctions.create_comment(comment);
    let is_success   = add_res && add_res.success ? true : false;

    if (is_success) {
      let comments_res = await ProjectFunctions.get_comments({ task_id: task_id });
      let comments     = comments_res && comments_res.success && comments_res.comments ? comments_res.comments : [];
      this.setState({ comments: comments, comment_text: '', loading_add_comment: false })
    } else {
      this.setState({ loading_add_comment: false })
    }
  }

  delete_comment_action = async (comment_id) => {
    this.setState({ deleting_comment_id: comment_id });

    let task_id    = this.state.task_id;
    let delete_res = await ProjectFunctions.delete_comment({ comment_id: comment_id });
    let is_success = delete_res && delete_res.success ? true : false;

    if (is_success) {
      let comments_res = await ProjectFunctions.get_comments({ task_id: task_id });
      let comments     = comments_res && comments_res.success && comments_res.comments ? comments_res.comments : [];
      this.setState({ comments: comments, deleting_comment_id: '' })
    } else {
      this.setState({ deleting_comment_id: '' })
    }
  }

  save_task_action = () => {
    let project    = getItem('project')
    let project_id = project && project.id ? project.id : '';

    let task = {
      id: this.state.task_id,
      name: this.state.name,
      assignee_id: this.state.assignee_id,
      type: StringUtils.displayStringToKey(this.state.type),
      status: StringUtils.displayStringToKey(this.state.status),
      progress: this.state.progress,
      track_hours: this.state.track_hours,
      estimated_hours: this.state.estimated_hours,
      completed_hours: this.state.completed_hours,
      phase_id: this.state.phase_id,
      project_id: project_id
    }

    if (this.props.save_task_action) {
      this.props.save_task_action(task, () => { this.close_edit_action() });
    }
  }

  close_edit_action = () => {
    this.setState({ display_editable_fields: false })
  }

  open_edit_action = () => {
    this.setState({ display_editable_fields: !this.state.display_editable_fields })
  }

  assignee_selected = (selected) => {
    let assignee_id = '';
    let members     = this.props.members        || [];
    let internal_us = this.props.internal_users || [];

    members.forEach((member, i) => {
      if (member.name === selected) {
        assignee_id = member.user_id;
      }
    });

    internal_us.forEach((internal_user, i) => {
      let with_prefix = 'internal_' + internal_user;
      if (with_prefix === selected) {
        assignee_id = with_prefix;
      }
    });

    this.setState({ assignee_id: assignee_id })
  }

  type_selected = (selected) => {
    this.setState({ type: selected });
  }

  status_selected = (selected) => {
    this.setState({ status: selected });
  }

  get_assignee_options = () => {
    let members   = this.props.members        || [];
    let internal  = this.props.internal_users || [];

    let internal_converted = internal.map((internal_user) => { return 'internal_' + internal_user });

    let names_arr = members.map((member) => { return member.name });
        names_arr = [ ...names_arr, ...internal_converted ];

    return names_arr;
  }

  get_type_options = () => {
    let task_types = this.props.task_types || [];
    let types_arr  = task_types.map((task) => { return StringUtils.keyToDisplayString(task) });
    return types_arr;
  }

  get_status_options = () => {
    let status_types = this.props.status_types || [];
    let status_arr   = status_types.map((status) => { return StringUtils.keyToDisplayString(status) });
    return status_arr;
  }

  get_assignee_label = () => {
    let assignee_label = '';
    let assignee_id    = this.state.assignee_id;
    let members        = this.props.members || [];
    let internal_users = this.props.internal_users || [];

    members.forEach((member, i) => {
      if (member.user_id === assignee_id) {
        assignee_label = member.name
      }
    });

    internal_users.forEach((internal_user, i) => {
      let with_prefix = 'internal_' + internal_user;
      if (with_prefix === assignee_id) {
        assignee_label = StringUtils.keyToDisplayString(with_prefix);
      }
    });

    return assignee_label;
  }

  get_type_label = () => {
    let type       = this.state.type;
    let type_label = StringUtils.keyToDisplayString(type);
    return type_label;
  }

  get_status_label = () => {
    let status       = this.state.status;
    let status_label = StringUtils.keyToDisplayString(status);
    return status_label;
  }

  increment_estimated_hours = () => {
    let current_hours = this.state.estimated_hours;
    let new_hours     = current_hours + 1;
    this.setState({ estimated_hours: new_hours });
  }

  decrement_estimated_hours = () => {
    let current_hours = this.state.estimated_hours;
    let new_hours     = current_hours > 0 ? current_hours -1 : 0;
    this.setState({ estimated_hours: new_hours });
  }

  increment_completed_hours = () => {
    let current_hours = this.state.completed_hours;
    let new_hours     = current_hours + 1;
    this.setState({ completed_hours: new_hours });
  }

  decrement_completed_hours = () => {
    let current_hours = this.state.completed_hours;
    let new_hours     = current_hours > 0 ? current_hours -1 : 0;
    this.setState({ completed_hours: new_hours });
  }

  increment_progress_percent = () => {
    let current_progress = this.state.progress;
    let new_progress     = current_progress < 100 ? current_progress + 10 : 100;
    this.setState({ progress: new_progress });
  }

  decrement_progress_percent = () => {
    let current_progress = this.state.progress;
    let new_progress     = current_progress > 0 ? current_progress - 10 : 0;
    this.setState({ progress: new_progress });
  }
}

export default TaskDetails;
