import React, { Component } from 'react';
import { ProjectFunctions } from '../functions';
import { Input, Button }    from '../components';

class AddProject extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      loading_save_project: false
    };
  }

  save_new_project = async () => {
    if (!this.state.name) { return }
    this.setState({ loading_save_project: true });

    let name        = this.state.name;
    let request_obj = { name: name };
    let project_res = await ProjectFunctions.create_new_project(request_obj);
    let is_success  = project_res && project_res.success ? true : false;

    if (is_success && this.props.onSuccess) {
      this.props.onSuccess();
    }

    this.setState({ loading_save_project: false });
  }

  render() {
    return (
      <div className='add-project-container'>
        <Input className='add-project-input' value={this.state.name} placeholder='Enter your name' onChange={ (name) => { this.setState({ name: name }) }} />
        <Button className='button-default add-project-button' loading={this.state.loading_save_project} title='Create Project' onClick={ () => { this.save_new_project() }} />
      </div>
    );
  }
}

export default AddProject;
