import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { TeamFunctions, ProjectFunctions } from '../functions';
import { Header, NavigationPane, TeamPane, TeamInvitePane, InternalUseAssigneesPane } from '../components';
import '../css/screens/team-screen.css';

class TeamScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      members: [],
      member_role_types: [],
      internal_users: [],
      og_internal_users: [],
      loading_role: false,
      project_id: '',
      loading_internal: false,
    };
  }

  componentDidMount = async () => {
    let project    = getItem('project')
    let project_id = project && project.id ? project.id : '';
    if (project_id) {
      this.setState({ loading_internal: true })
      let project_types_res = await ProjectFunctions.get_project_types(project_id);
      let members_res       = await TeamFunctions.get_team_members(project_id);
      let internal_users_res= await TeamFunctions.get_internal_users(project_id);
      let is_success        = members_res && members_res.success ? true : false;
      let members           = members_res && members_res.members ? members_res.members : [];
      let project_types     = project_types_res && project_types_res.success && project_types_res.project_types ? project_types_res.project_types : {};
      let member_types      = project_types     && project_types.team_member_role_types ? project_types.team_member_role_types : [];
      let internal_users    = internal_users_res&& internal_users_res.project_internal_users && internal_users_res.project_internal_users.internal_users ? internal_users_res.project_internal_users.internal_users : [];
      this.setState({ project_id: project_id, members: members, member_role_types: member_types, loading_internal: false, internal_users: internal_users, og_internal_users: [ ...internal_users ] });
    }
  }

  render() {
    return (
      <div>
        <Header navigate={this.props.navigate} />
        <div className='team-screen-container'>
          <div className='main-pane main-container-sections'>
            <NavigationPane tab='team' navigate={this.props.navigate} />
            <div style={{ flex: 1, marginLeft: 10 }}>
              <TeamPane members={this.state.members} member_types={this.state.member_role_types} updateMembers={this.update_team_members} />
            </div>
          </div>
          <div className='right-pane'>
            <TeamInvitePane />
            <InternalUseAssigneesPane title='Internal Assignees' loading={this.state.loading_internal} changeAction={ (new_values) => { this.internal_users_change_action(new_values) } } saveAction={ (arr, save_success, save_failure) => { this.internal_users_save_action(arr, save_success, save_failure) } } types={this.state.internal_users} og_types={this.state.og_internal_users} />
          </div>
        </div>
      </div>
    );
  }

  internal_users_change_action = (internal_users) => {
    this.setState({ internal_users: internal_users })
  }

  internal_users_save_action = async (in_users, success_action, failure_action) => {
    let project_id     = this.state.project_id;
    let internal_users = in_users;
    let update_req_obj = { project_id: project_id, internal_users: internal_users };

    let internal_users_res = await TeamFunctions.update_internal_users(update_req_obj);
    let is_success         = internal_users_res && internal_users_res.success;

    if (is_success) {
      let internal_users_res    = await TeamFunctions.get_internal_users(project_id);
      let pulled_internal_users = internal_users_res&& internal_users_res.project_internal_users && internal_users_res.project_internal_users.internal_users ? internal_users_res.project_internal_users.internal_users : [];
      success_action();
      this.setState({ internal_users: pulled_internal_users, og_internal_users: [ ...pulled_internal_users ] });
    } else {
      this.setState({ internal_users: this.state.og_internal_users });
      failure_action();
    }
  }

  update_team_members = async (change_data) => {
    let member  = change_data.member    || {};
    let new_arr = change_data.new_array || [];
    let proj_id = member.project_id;

    let members_res      = await TeamFunctions.update_team_member(member);
    let updated_mems_res = await TeamFunctions.get_team_members(proj_id);
    let updated_members  = updated_mems_res && updated_mems_res.success && updated_mems_res.members ? updated_mems_res.members : new_arr;

    this.setState({ members: updated_members })
  }
}

export default TeamScreen;
