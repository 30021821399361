import React, { Component } from 'react';
import { ProjectFunctions } from '../functions';
import { setItem, getItem } from '../storage';
import { Button, Line, Input } from '../components';
import '../css/components/new-phase.css';

class NewPhase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      loading_save_phase: false
    };
  }

  save_new_phase = async () => {
    if (!this.state.name) { return }

    let project     = getItem('project')
    let project_id  = project && project.id ? project.id : '';
    let name        = this.state.name;
    let request_obj = { name: name, project_id: project_id };

    if (this.props.new_phase_action) {
      this.props.new_phase_action(request_obj);
    }

    this.setState({ name: '' });
  }

  render() {
    return (
      <div className='right-pane-container'>
        <div className='title new-phase-title'>New Phase</div>
        <div className='new-phase-input-row'>
          <div className='new-phase-input-key'>Name</div>
          <Input className='new-phase-input' value={this.state.name} placeholder='Enter Name' onChange={ (name) => { this.setState({ name: name }) }} />
        </div>
        <Button className='new-phase-button' loading={this.props.loading} title='Create Phase' onClick={ () => { this.save_new_phase() }} />
      </div>
    );
  }
}

export default NewPhase;
