import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { ProjectFunctions } from '../functions';
import { Button, Line, Input } from '../components';
import '../css/components/phase-details.css';

class PhaseDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      phase_id: '',
      name: '',
      comments: [],
      comments_pulled: false,
      comment_text: '',
      display_editable_fields: false
    };
  }

  configure_state = async () => {
    let data  = this.props.data;
    let phase = data.phase;
    if (phase && phase.id && this.state.phase_id !== phase.id) {
      let comments_res = await ProjectFunctions.get_comments({ phase_id: phase.id });
      let comments     = comments_res && comments_res.success && comments_res.comments ? comments_res.comments : [];
      this.setState({
        phase_id: phase.id,
        name: phase.name,
        display_editable_fields: false,
        comments: comments
      })
    }
  }

  componentDidMount = () => {
    this.configure_state();
  }

  componentDidUpdate = () => {
    this.configure_state();
  }

  render_editable_fields = () => {
    if (!this.state.display_editable_fields) { return null }

    return <div className='phase-edit-container'>
      <div className='new-phase-input-row'>
        <div className='new-phase-input-key'>Name</div>
        <Input className='new-phase-input' value={this.state.name} placeholder='Enter Name' onChange={ (name) => { this.setState({ name: name }) }} />
      </div>
      <Button className='comment-add-button' loading={ this.props.loading } onClick={ () => { this.save_phase_action() }} title='Save Phase' />
    </div>
  }

  render_comments_section = () => {
    let user_id      = getItem('user_id');
    let comments     = this.state.comments;
    let comment_rows = comments.map((comment) => {
      let is_loading = this.state.deleting_comment_id === comment.id;
      let delete_str = is_loading ? 'Deleting...' : 'Delete';
      let show_del   = comment.user_id === user_id ? true : false;
      return <div className='comment-container'>
        <div className='comment-sender-container'>
          <div className='comment-sender-name'>{ comment.name }</div>
          { show_del ? <div className='comment-delete-text' onClick={ () => { this.delete_comment_action(comment.id) }}>{ delete_str }</div> : null }
        </div>
        <div className='comment-text'>{ comment.text }</div>
      </div>
    })

    return <div className='comments-section'>
      <div className='title task-details-title'>Comments</div>

      { comment_rows }

      <div className='comment-textarea-container'>
        <textarea
          className='comment-input-textarea'
          value={this.state.comment_text}
          onChange={ (event) => {
            let text = event && event.target && event.target.value;
            this.setState({ comment_text: text });
          }}
          rows={4}
        />
      </div>
      <div className='comment-add-button-container'>
        <Button className='comment-add-button' title='Add Comment' loading={ this.state.loading_add_comment } onClick={ () => { this.add_comment() }}/>
      </div>
    </div>
  }

  render_close_button = () => {
    if (!this.props.display_close) { return null }
    return <div className='flex-container-row' onClick={ () => { this.props.close_action() }}>
      <div className='flex-one'></div>
      <div className='right-pane-close-curve-containers'>
        <div className='right-pane-close-curve-left' />
      </div>
      <div className='right-pane-close-button'>
        <span className='fa-solid fa-circle-xmark modal-close-button right-pane-close-button-icon' onClick={ () => { this.onClose() }}/>
        <div className='right-pane-close-button-text'>Close</div>
      </div>
      <div className='right-pane-close-curve-containers'>
        <div className='right-pane-close-curve-right' />
      </div>
      <div className='right-pane-close-button-right-margin'></div>
    </div>
  }

  render() {
    return (
      <div>
        { this.render_close_button() }
        <div className='right-pane-container'>
          <div className='task-details-title-container'>
            <div className='title task-details-title'>Phase</div>
            <div className='task-details-edit-button' onClick={ () => { this.open_edit_action() }}>{ this.state.display_editable_fields ? 'Cancel' : 'Edit' }</div>
          </div>
          <div className='task-details-subtitle'>{ this.state.name }</div>

          { this.render_editable_fields()  }

          <Line />

          { this.render_comments_section() }

        </div>
      </div>
    );
  }

  save_phase_action = async () => {
    let project    = getItem('project')
    let project_id = project && project.id ? project.id : '';
    let phase      = { id: this.state.phase_id, name: this.state.name, project_id: project_id }

    if (this.props.save_phase_action) {
      this.props.save_phase_action(phase, () => { this.close_edit_action() });
    }
  }

  close_edit_action = () => {
    this.setState({ display_editable_fields: false });
  }

  add_comment = async () => {
    this.setState({ loading_add_comment: true })
    if (!this.state.comment_text) { return }
    let user_id  = getItem('user_id');
    let phase_id = this.state.phase_id;
    let text     = this.state.comment_text;
    let comment  = {
      phase_id: phase_id,
      user_id: user_id,
      text: text
    }

    let add_res      = await ProjectFunctions.create_comment(comment);
    let is_success   = add_res && add_res.success ? true : false;

    if (is_success) {
      let comments_res = await ProjectFunctions.get_comments({ phase_id: phase_id });
      let comments     = comments_res && comments_res.success && comments_res.comments ? comments_res.comments : [];
      this.setState({ comments: comments, comment_text: '', loading_add_comment: false })
    } else {
      this.setState({ loading_add_comment: false })
    }
  }

  delete_comment_action = async (comment_id) => {
    this.setState({ deleting_comment_id: comment_id });

    let phase_id   = this.state.phase_id;
    let delete_res = await ProjectFunctions.delete_comment({ comment_id: comment_id });
    let is_success = delete_res && delete_res.success ? true : false;

    if (is_success) {
      let comments_res = await ProjectFunctions.get_comments({ phase_id: phase_id });
      let comments     = comments_res && comments_res.success && comments_res.comments ? comments_res.comments : [];
      this.setState({ comments: comments, deleting_comment_id: '' })
    } else {
      this.setState({ deleting_comment_id: '' })
    }
  }

  open_edit_action = () => {
    this.setState({ display_editable_fields: !this.state.display_editable_fields })
  }
}

export default PhaseDetails;
