import React, { Component } from 'react';
import { setItem, getItem } from '../storage';
import { Line, Input, Button } from '../components';
import '../css/components/settings-project-name.css';

class SettingsProjectName extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  change_value_action = (project_name) => {
    this.props.projectNameChange(project_name)
  }

  render() {
    if (this.determine_hide()) { return null }
    let project_name = this.props.project && this.props.project.name ? this.props.project.name : '';
    return <div className='settings-name-card-container'>
      <div className='settings-name-card'>
        <div className='settings-name-card-header'>
          <div className="title">{ 'Project Name' }</div>
        </div>

        <Line className='settings-name-seperator-line' />

        <div className='settings-name-input-container'>
          <div className='flex-container-row'>
            <Input placeholder='Enter Project Name' className='type-input' value={project_name} onChange={ (type_value) => { this.change_value_action(type_value) }} />
          </div>
          <Button className='settings-name-save-button' loading={this.props.loading} onClick={ () => { this.props.projectSaveAction() }} title='Save' />
        </div>

      </div>
    </div>
  }

  determine_hide = () => {
    let project           = getItem('project')
    let project_id        = project && project.id ? project.id : '';
    let hide              = false;
    let all_member_access = getItem('member_access') || {};
    let project_access    = all_member_access[project_id];

    if (project_access === 'tasks' || project_access === 'phases') {
      hide = true;
    }

    return hide;
  }
}

export default SettingsProjectName;
